import styled from '@emotion/styled';

const CoolButton = ({ label, onClick }) => <Button onClick={onClick}>{label}</Button>

export default CoolButton


const Button = styled.button`
    width: 160px;
    height: 46px;
    border: none;
    outline: none;
    background: #ff7a46;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    font-family: 'Yomogi', cursive;
    font-weight: 900;
    font-size: 22px;
`