import * as s from './Signup.style';
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';

// Components
import PorchForm from './Porch/Porch';
import ActForm from './Act/Act';

const Signup = () => {
  const [optionHovered, setOptionHovered] = useState([false, false]);
  const [btnSelected, setBtnSelected] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSignupClick = (button) => {
    if (button === 'act') btnSelected === 'act' ? setBtnSelected(null) : setBtnSelected('act');
    if (button === 'porch') btnSelected === 'porch' ? setBtnSelected(null) : setBtnSelected('porch');
  };

  const renderForm = () => {
    if (!btnSelected) return null;

    if (btnSelected === 'act') return <ActForm />;
    if (btnSelected === 'porch') return <PorchForm />;
  };

  return (
    <motion.nav initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.2 }}>
      <s.SignupContainer>
        <s.Header>
          <span style={{ color: '#ff7a46' }}>S</span>ign <span style={{ color: '#ff7a46' }}>U</span>p
        </s.Header>
        <s.DateDesktop>
          SATURDAY
          <br />
          August 24th 2024, 12pm-7pm
        </s.DateDesktop>
        <s.DateMobile>
          Saturday
          <br />
          August 24th 2024, 12pm-7pm
        </s.DateMobile>

        <h3>
          This is a free event welcome for anyone to attend.
          <br />
          Visit our{' '}
          <span
            style={{ color: '#5fcbee', fontWeight: 600, letterSpacing: '2px', cursor: 'pointer' }}
            onClick={() => window.open('https://www.facebook.com/galtporchfest', '_blank')}
          >
            FACEBOOK
          </span>{' '}
          to stay in the loop.
        </h3>
        <hr />

        <h3 style={{ textAlign: 'center', margin: '0 0 26px 0', fontWeight: 100, marginTop: '20px' }}>Are you a performer or a host?</h3>
        <s.SignupButtonsWrapper>
          <s.SignupWrapper
            onMouseEnter={() => setOptionHovered([true, false])}
            onMouseLeave={() => setOptionHovered([false, false])}
            onClick={() => handleSignupClick('act')}
            selected={btnSelected === 'act'}
          >
            <s.IconWrapper hovered={optionHovered[0]} selected={btnSelected === 'act'}>
              <s.Icon src="icons/guitar.png" />
            </s.IconWrapper>
            I have an act
          </s.SignupWrapper>
          <s.SignupWrapper
            onMouseEnter={() => setOptionHovered([false, true])}
            onMouseLeave={() => setOptionHovered([false, false])}
            onClick={() => handleSignupClick('porch')}
            selected={btnSelected === 'porch'}
          >
            <s.IconWrapper hovered={optionHovered[1]} selected={btnSelected === 'porch'}>
              <s.Icon src="icons/porch.png" />
            </s.IconWrapper>
            I have a porch
          </s.SignupWrapper>
        </s.SignupButtonsWrapper>

        <s.FormWrapper>{renderForm()}</s.FormWrapper>
      </s.SignupContainer>
    </motion.nav>
  );
};

export default Signup;
