import * as s from './NotFound.style'
import { motion } from 'framer-motion'
import { useState, useEffect } from 'react'

import { jokes } from 'Jokes';

const NotFound = () => {
    const [randomJoke, setJoke] = useState('');

    useEffect(() => {
        const rando = jokes[Math.floor(Math.random() * jokes.length)];    
        setJoke(rando)
    }, [])

    return (
        <motion.nav
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.2 }}
        >
            
            <s.Header><span style={{ color: '#ff7a46'}}>P</span>age <span style={{ color: '#ff7a46'}}>D</span>oesn't <span style={{ color: '#ff7a46'}}>E</span>xist</s.Header>
            <h3 style={{ textAlign: 'center', fontWeight: 100 }}>My friend, you appear to have landed on a page that does not exist...</h3>
            <h3 style={{ textAlign: 'center', fontWeight: 100, color: '#ff7a46' }}>...but since you came all this way, here is a dad joke for you</h3>
            <s.Joke>{randomJoke}</s.Joke>
        </motion.nav>
    ) 
}

export default NotFound