import styled from '@emotion/styled';

export const PorchFormWrapper = styled.div`
  display: ${(p) => (p.isRegistrationClosed ? 'none' : 'flex')};
  justify-content: space-between;

  // ------ iPad Pro
  @media (max-width: 1200px) {
    display: ${(p) => (p.isRegistrationClosed ? 'none' : 'block')};
  }
`;

export const PorchFormLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;

  .last-input {
    margin-bottom: 16px;
  }

  // ------ iPad Pro
  @media (max-width: 1200px) {
    width: 670px;
    margin: 0 auto;

    .last-input {
      margin-bottom: 24px;
    }
  }

  // ------ Phone
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const PorchFormRightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;

  // ------ iPad Pro
  @media (max-width: 1200px) {
    width: 670px;
    margin: 0 auto;
  }

  // ------ Phone
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const SingleInput = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  width: 100%;
  ${(p) => !p.rightSide && 'justify-content: space-between;'}

  // ------ iPad Pro
    @media (max-width: 1200px) {
    justify-content: space-between;

    ${(p) => p.lastChild && 'margin-bottom: 0 !important'}
  }

  // ------ Phone
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Label = styled.label`
  font-size: 16px;
  text-transform: uppercase;
  font-family: 'Yomogi', cursive;
  ${(p) => !p.rightSide && 'margin-left: auto;'}
  padding-right: 26px;
  white-space: nowrap;

  // ------ iPad Pro
  @media (max-width: 1200px) {
    margin-left: 0;
  }

  // ------ Phone
  @media (max-width: 1024px) {
    padding-bottom: 10px;
  }
`;

export const Input = styled.input`
  background: #f5f6fa;
  border: 1px solid #7f8fa6;
  padding: 10px 20px;
  color: #000;
  text-transform: uppercase;
  transition: 0.2s ease;
  font-family: 'Yomogi', cursive;
  font-size: 16px;
  letter-spacing: 2px;
  width: ${(p) => (!p.rightSide ? '300px' : '100%')};

  // ------ iPad Pro
  @media (max-width: 1200px) {
    width: 400px;
  }

  // ------ Phone
  @media (max-width: 1024px) {
    width: 100%;
    box-sizing: border-box;
    padding: 8px 16px;
  }

  &:focus {
    outline: none !important;
    border: 1px solid #5fcbee;
    box-shadow: 0 0 16px #5fcbee;
  }
  &::placeholder {
    color: #738084;
  }
`;

export const ElectricityWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${(p) =>
    p.checked &&
    `.MuiSwitch-colorPrimary {
          color: #5fcbee !important
        };
        .MuiSwitch-track {
          background-color: #5fcbee !important
        }`}

  // ------ iPad Pro
    @media (max-width: 1200px) {
    margin: 0;
  }
`;

export const ElectricityHeader = styled.h3`
  font-weight: 100;
  display: inline;
  align-items: center;
  padding: 0 20px 8px 0;

  // ------ iPad Pro
  @media (max-width: 1200px) {
    margin: 16px 0 0;
    padding: 0 20px 18px 0;
  }
`;

export const LightBulb = styled.img`
  width: 40px;
  margin: 0 0 10px 12px;

  // ------ iPad Pro
  @media (max-width: 1200px) {
    margin: 0 0 0 6px;
    padding-bottom: 6px;
  }
`;

export const ExtraActWrapper = styled.div`
  display: flex;
  margin: 6px 0 12px;

  ${(p) =>
    p.checked &&
    `.MuiSwitch-colorPrimary {
          color: #5fcbee !important
        };
        .MuiSwitch-track {
          background-color: #5fcbee !important
        }`}

  // ------ iPad Pro
    @media (max-width: 1200px) {
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0 ${(p) => (p.hasOwnAct ? '28px' : '16px')} 7px;
  }

  // ------ Phone
  @media (max-width: 1024px) {
    margin: 10px 0 ${(p) => (p.hasOwnAct ? '28px' : '0')} 0;
  }
`;

export const ExtraActHeader = styled.h3`
  font-weight: 100;
  display: inline;
  padding: 0 20px 8px 0;
  margin-top: 0;

  // ------ iPad Pro
  @media (max-width: 1200px) {
    margin-bottom: 0;
    padding: 0 20px 6px 0;
  }
`;

export const RockOn = styled.img`
  width: 20px;
  height: 20px;
  margin: 8px 0 0 18px;

  // ------ iPad Pro
  @media (max-width: 1200px) {
    margin: -4px 8px 0 19px;
  }
`;

export const ActDescription = styled.textarea`
  background: #f5f6fa;
  border: 1px solid #7f8fa6;
  padding: 10px 20px;
  color: #000;
  text-transform: uppercase;
  transition: 0.2s ease;
  font-family: 'Yomogi', cursive;
  font-size: 16px;
  letter-spacing: 2px;
  width: 100%;
  max-height: 108px;
  resize: none;

  // ------ Phone
  @media (max-width: 1024px) {
    box-sizing: border-box;
    padding: 8px 16px;
  }

  &:focus {
    outline: none !important;
    border: 1px solid #5fcbee;
    box-shadow: 0 0 16px #5fcbee;
  }
  &::placeholder {
    color: #738084;
  }
`;

export const ButtonContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 30px;

  // Bottom is cutoff because of Map library hack. Add padding to fix that
  @media (min-width: 1024px) {
    margin-bottom: 40px;
  }
`;

export const MapWrapper = styled.div`
  width: 500px;
  height: 300px;
  box-shadow: 0px 0px 14px #ff7a46;
  border-radius: 10px;
  margin: 0 auto;
  margin-bottom: 30px;

  // ------ Phone
  @media (max-width: 570px) {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const RegistrationClosedMessage = styled.div`
  display: ${(p) => (p.isRegistrationClosed ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  background: #2b3240;
  width: auto;
  max-width: 440px;
  border-radius: 15px;
  box-shadow: 0 0 10px #ff7a46;
  margin: 0 auto 80px;
  padding: 20px;

  h4 {
    display: inline-block;
    margin: 0;
    font-weight: 100;

    // ------ Phone
    @media (max-width: 1024px) {
      text-align: center;
    }
  }

  // ------ Phone
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const WarningIcon = styled.img`
  height: 30px;
  padding: 0 30px 0 0;

  // ------ Phone
  @media (max-width: 1024px) {
    padding: 0 0 20px 0;
  }
`;
