import * as s from './Schedule.style';
import { useState, useRef, useContext, useEffect } from 'react';
import { schedule } from 'Performers';
import { useHistory } from 'react-router-dom';

// Context
import AppContext from 'Context';

// Google Map
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react';
import * as CustomMap from 'Map';

const Schedule = (props) => {
  // Context
  const context = useContext(AppContext);
  const { isMobile, isPhone, handleSelectPerformer } = context;

  // History
  const history = useHistory();

  // Ref
  const mapWrapperRef = useRef();

  // State
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);

  // Effect to change factory position property from 'absolute' to 'relative'
  useEffect(() => {
    if (mapWrapperRef.current) {
      mapWrapperRef.current.children[0].style.position = 'relative';

      // Manually set width for mobile
      const wrapperWidth = mapWrapperRef.current.offsetWidth;
      mapWrapperRef.current.children[0].children[0].style.width = wrapperWidth;
    }
  }, []);

  // Scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onMapClicked = () => {
    setShowingInfoWindow(false);
    setActiveMarker(null);
    setSelectedPlace(null);
  };

  const onMarkerClick = (props, marker, e) => {
    setShowingInfoWindow(true);
    setActiveMarker(marker);
    setSelectedPlace(props);
  };

  const onPerformerClick = (name) => {
    handleSelectPerformer(name);
    history.push('performers');
  };

  return (
    <s.ScheduleWrapper>
      <s.Header>
        <span style={{ color: '#ff7a46' }}>S</span>chedule
      </s.Header>
      <s.DateDesktop>
        SATURDAY
        <br />
        August 24th 2024, 12pm-7pm
        <br />
        <h3 style={{ margin: 0 }}>
          <a href="https://galtporchfest.ca/static/media/open-map-pdf.html" rel="noreferrer" target="_blank" style={{ color: '#ff7a46' }}>
            Click here to download the PDF map
          </a>
        </h3>
      </s.DateDesktop>
      <s.DateMobile>
        Saturday
        <br />
        August 24th 2024, 12pm-7pm
        <br />
        <h3 style={{ margin: 0 }}>
          <a href="https://galtporchfest.ca/static/media/2024-galt-porchfest-map.pdf" rel="noreferrer" target="_blank" style={{ color: '#ff7a46' }}>
            Click here to download the PDF map
          </a>
        </h3>
      </s.DateMobile>
      {/* <h3 style={{ textAlign: 'center', marginTop: '40px', fontWeight: 100 }}>Event schedule and map will be available a few weeks prior to the date.</h3> */}
      <h3 style={{ textAlign: 'center', fontWeight: 100, marginBottom: '2px' }}>
        <span style={{ color: '#ff7a46' }}>W</span>ho? <span style={{ color: '#ff7a46' }}>W</span>hen? <span style={{ color: '#ff7a46' }}>W</span>here?{' '}
        <span style={{ color: '#ff7a46' }}>W</span>hy?
      </h3>
      <h1
        style={{
          textAlign: 'center',
          color: '#ff7a46',
          margin: '0 0 8px',
          fontSize: '200%'
        }}
      >
        &#8595;
      </h1>
      <s.MapWrapper isPhone={isPhone} ref={mapWrapperRef}>
        <Map
          google={props.google}
          onClick={onMapClicked}
          style={{
            height: isPhone ? '400px' : '500px',
            width: `${isMobile ? '' : '1024px'}`,
            borderRadius: '10px'
          }}
          styles={CustomMap.mapStyle}
          loading="async"
          initialCenter={
            isPhone
              ? {
                  lat: 43.3585742,
                  lng: -80.3280768
                }
              : {
                  lat: 43.3594742,
                  lng: -80.3201768
                }
          }
          zoom={isPhone ? 13.9 : 14.9}
        >
          {CustomMap.markers.map((marker, index) => {
            const iconScaledSie = marker.isWashroom ? 28 : 32;
            return (
              <Marker
                key={index}
                act={marker.act}
                address={marker.address}
                time={marker?.time}
                title={marker?.title}
                position={marker.position}
                isWashroom={marker.isWashroom}
                icon={{
                  url: marker.isWashroom ? '/icons/map/wc.png' : '/icons/map/orange-marker.png',
                  anchor: new props.google.maps.Point(16, 16),
                  scaledSize: new props.google.maps.Size(iconScaledSie, iconScaledSie)
                }}
                onClick={onMarkerClick}
              />
            );
          })}
          <Marker
            act=""
            address=""
            time=""
            title="Free Street Parking"
            position={{ lat: 43.356943899781335, lng: -80.3335155011705 }}
            isWashroom
            isParking
            icon={{
              url: '/icons/map/car.png',
              anchor: new props.google.maps.Point(16, 16),
              scaledSize: new props.google.maps.Size(27, 27)
            }}
            onClick={onMarkerClick}
          />
          <Marker
            act=""
            address=""
            time=""
            title={'Free Parking / Dickson Park'}
            position={{ lat: 43.363309, lng: -80.319685 }}
            isWashroomtart
            isParking
            icon={{
              url: '/icons/map/car.png',
              anchor: new props.google.maps.Point(16, 16),
              scaledSize: new props.google.maps.Size(27, 27)
            }}
            onClick={onMarkerClick}
          />
          <Marker
            act=""
            address=""
            time=""
            title="Queen's Square Parking Lot"
            position={{ lat: 43.3598957468194, lng: -80.31786147505038 }}
            isWashroom
            isParking
            icon={{
              url: '/icons/map/car.png',
              anchor: new props.google.maps.Point(16, 16),
              scaledSize: new props.google.maps.Size(27, 27)
            }}
            onClick={onMarkerClick}
          />
          <Marker
            act=""
            address=""
            time=""
            title={'Free Parking / Victoria Park'}
            position={{ lat: 43.358, lng: -80.326228 }}
            isWashroom
            isParking
            icon={{
              url: '/icons/map/car.png',
              anchor: new props.google.maps.Point(16, 16),
              scaledSize: new props.google.maps.Size(27, 27)
            }}
            onClick={onMarkerClick}
          />
          <Marker
            act=""
            address=""
            time=""
            title="'Krankie Frankie' food truck, 2:00 - 5:00pm"
            position={{ lat: 43.36143, lng: -80.32285 }}
            isWashroom
            isParking
            icon={{
              url: '/icons/map/food_truck.png',
              anchor: new props.google.maps.Point(16, 16),
              scaledSize: new props.google.maps.Size(25, 25)
            }}
            onClick={onMarkerClick}
          />
          <InfoWindow marker={activeMarker} visible={showingInfoWindow} loading="async">
            <s.InfoWindowWrapper>
              {!selectedPlace?.isWashroom && <s.Icon src={'icons/house-dark.png'} />}
              <s.InfoWindowText isAddress>{selectedPlace?.address}</s.InfoWindowText>
              {selectedPlace?.address && <hr />}
              {selectedPlace?.isWashroom ? (
                <>
                  <s.InfoWindowHeader isParking={selectedPlace?.isParking} isWashroom>
                    {selectedPlace?.title}
                  </s.InfoWindowHeader>
                  <s.InfoWindowText>{selectedPlace?.time}</s.InfoWindowText>
                </>
              ) : (
                selectedPlace?.act.map((act) => (
                  <div key={act.title}>
                    <s.InfoWindowHeader isWashroom={false}>{act.title}</s.InfoWindowHeader>
                    <s.InfoWindowText>{act.time}</s.InfoWindowText>
                  </div>
                ))
              )}
            </s.InfoWindowWrapper>
          </InfoWindow>
        </Map>
      </s.MapWrapper>
      <hr />
      <s.PerformersWrapper>
        {schedule.map((act) => (
          <s.Performer key={act.name} onClick={() => onPerformerClick(act.name)}>
            <h3>{act.time}</h3>
            <h4>{act.porch}</h4>
            <h1>{act.name}</h1>
            <h2>{act.genre}</h2>
            <hr />
          </s.Performer>
        ))}
      </s.PerformersWrapper>
    </s.ScheduleWrapper>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyARh_P0B3FkRNPP1IXM3Ym2RZiBc_gha_M',
  loading: 'async'
})(Schedule);
