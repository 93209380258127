import styled from '@emotion/styled';

export const Header = styled.h1`
  font-size: 160%;
  text-align: center;
  letter-spacing: 1px;
  font-family: 'Indie Flower', cursive;
  font-weight: 100;
  margin: 0 0 14px;
`;

// Desktop view ----------------------------------------------------
export const GalleryContainerDesktop = styled.div`
  display: flex;
  justify-content: space-between
  overflow: hidden;
  max-height: calc(100vh - 250px); // view container minus navbar and footer
  min-height: calc(100vh - 250px); // view container minus navbar and footer
`;

export const FilterWrapper = styled.div`
  min-width: 260px;
  max-width: 260px;
  overflow-y: auto;
  background-color: #2b324000;
  padding: 8px;
  z-index: 10;

  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #3c4659;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #5d6b85;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #747d8c;
  }
`;

export const FilterName = styled.div`
  font-weight: 100;
  text-align: center;
  border-radius: 15px;
  padding: 4px 0;
  cursor: pointer;
  background-color: transparent;
  transition: 0.1s ease-in;
  margin-bottom: 14px;

  ${(p) =>
    !p.isSelected &&
    `
    &:hover {
      background-color: #ff7a464D;
    }
  `}

  ${(p) =>
    p.isSelected &&
    `
    background-color: #ff7a46;
    transition: 0.1s ease-in;
    font-weight: 600;
    color: #333;

    border-radius: 15px;
    box-shadow: 0 0 10px #ff7a46;
  `}
`;

export const PhotosWrapper = styled.div`
  // width: 80%;
  overflow: hidden;
`;

// Mobile view ----------------------------------------------------
export const GalleryContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  hr {
    margin: 30px;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0));
  }
`;

export const FilterWrapperMobile = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const FilterMobileLabel = styled.h3`
  font-weight: 100;
`;

export const ArtistSelectDropdown = styled.div`
  width: 50%;
  margin: 0 auto;

  .MuiSelect-icon {
    color: #ff7a46;
  }

  // ------ Phone
  @media (max-width: 1024px) {
    width: 100%;
  } ;
`;

export const ImagesWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageMobile = styled.img`
  width: 100%;
  height: auto;
  border: 2px solid #636e72;
  border-radius: 10px;
`;

export const HrWrapper = styled.div`
    width: 80%;
    margin: 0 auto;
    position; relative;

    hr {
        margin: 15px;
        border: 0;
        height: 1px;
        background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.75),
            rgba(255, 255, 255, 0)
        );
    }
`;
