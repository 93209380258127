import * as s from './Media.style';
import { motion } from 'framer-motion';
import { useEffect } from 'react';

const Media = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const articles = [
    {
      url: 'https://www.cambridgetoday.ca/local-news/performers-for-the-galt-porchfest-have-been-announced-7317018',
      text: 'Performers for the Galt Porchfest have been announced',
      date: 'July 24, 2023',
      img: 'img/news/cambridge_today.jpeg',
      year: 2023
    },
    {
      url: 'https://www.cambridgetimes.ca/news/what-is-galt-porchfest-the-community-performance-festival-turns-two-this-august/article_c8f012d0-820d-539a-b959-92535c4c274c.html?',
      text: 'What is Galt Porchfest? The community performance festival turns two this August',
      date: 'June 14, 2023',
      img: 'img/news/cambridge_times.png',
      year: 2023
    },
    {
      url: 'https://kitchener.ctvnews.ca/galt-porchfest-is-back-1.6045075',
      text: 'Galt Porchfest is back',
      date: 'August 27, 2022',
      img: 'img/news/ctv.jpeg',
      year: 2022
    },
    {
      url: 'https://www.therecord.com/local-cambridge/news/2022/08/25/a-day-for-porch-partying-in-galt-and-preston-areas-of-cambridge.html',
      text: 'A day for porch partying in Galt and Preston areas of Cambridge',
      date: 'August 25, 2022',
      img: 'img/news/record.png',
      year: 2022
    },
    {
      url: 'https://kitchener.citynews.ca/local-news/galt-porchfest-is-bringing-live-music-to-your-front-doorstep-5661703',
      text: 'Galt Porchfest is bringing live music to your front doorstep',
      date: 'August 5, 2022',
      img: 'img/news/city_news.png',
      year: 2022
    },
    {
      url: 'https://www.cambridgetoday.ca/local-sports/galt-porchfest-returning-in-august-to-a-porch-near-you-5434896',
      text: 'Galt PorchFest returning in August to a porch near you',
      date: 'June 2, 2022',
      img: 'img/news/cambridge_today.jpeg',
      year: 2022
    },
    {
      url: 'https://kitchener.ctvnews.ca/community-takes-in-performances-in-front-of-their-doors-for-galt-porchfest-1.5565467',
      text: 'Community takes in performances in front of their doors for Galt Porchfest',
      date: 'August 28, 2021',
      img: 'img/news/ctv.jpeg',
      year: 2021
    },
    {
      url: 'https://www.cambridgetimes.ca/whatson-story/10464005--an-event-to-bring-the-community-together-again-cambridge-resident-launches-galt-porchfest/',
      text: "'An event to bring the community together again': Cambridge resident launches Galt Porchfest",
      date: 'August 26, 2021',
      img: 'img/news/cambridge_times.png',
      year: 2021
    },
    {
      url: 'https://www.cambridgetoday.ca/local-news/music-to-ring-through-galt-for-porchfest-4235757',
      text: 'Music to ring through Galt for porchfest',
      date: 'August 19, 2021',
      img: 'img/news/cambridge_today.jpeg',
      year: 2021
    }
  ];

  return (
    <motion.nav initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.2 }}>
      <s.Header>
        <span style={{ color: '#ff7a46' }}>G</span>alt <span style={{ color: '#ff7a46' }}>P</span>orchfest <span style={{ color: '#ff7a46' }}>I</span>n the{' '}
        <span style={{ color: '#ff7a46' }}>N</span>ews
      </s.Header>
      <h3 style={{ textAlign: 'center', fontWeight: 100 }}>Some cool places we have been featured in...</h3>
      <s.YearLabel style={{ textAlign: 'center' }}>2023:</s.YearLabel>
      {articles.map((article, index) => {
        return (
          <>
            {article.year === 2022 && articles[index - 1].year !== article.year && <s.YearLabel style={{ textAlign: 'center' }}>{article.year}:</s.YearLabel>}
            {article.year === 2021 && articles[index - 1].year !== article.year && <s.YearLabel style={{ textAlign: 'center' }}>{article.year}:</s.YearLabel>}
            <s.ArticleWrapper onClick={() => window.open(article.url, '_blank')} key={article.text} isLastChild={index === articles.length - 1}>
              <s.ArticleImageWrapper>
                <s.ArticleImage src={article.img} />
              </s.ArticleImageWrapper>
              <s.ArticleDescriptionWrapper>
                <s.Text>{article.text}</s.Text>
                <s.Date>{article.date}</s.Date>
              </s.ArticleDescriptionWrapper>
            </s.ArticleWrapper>
          </>
        );
      })}
    </motion.nav>
  );
};

export default Media;
