import React from 'react';
import * as s from './RegistrationFormMessage.styled';
import { Link } from 'react-router-dom';

const RegistrationFormMessage = ({ messageForType = 'performers', messageType = 'closed' }) => {
  // messageForType - "performers" / "hosts"
  // messageType - "open" / "closed"
  const currentYear = new Date().getFullYear();
  return (
    <s.MessageWrapper>
      <s.Icon src={`icons/${messageType === 'closed' ? 'warning' : 'party'}.png`} />
      <h4>
        {/* Registration for <span style={{ color: '#ff7a46', letterSpacing: '2px' }}>{messageForType === 'performers' ? 'PERFORMERS' : 'HOSTS'}</span> will open in late May of{' '}
        {currentYear}. */}
        Due to factors outside of our control, {currentYear} festival will have a limited number of performers. <br />
        Official registration for <span style={{ color: '#ff7a46', letterSpacing: '2px' }}>{messageForType === 'performers' ? 'PERFORMERS' : 'HOSTS'}</span> is {messageType}.{' '}
        <br />
        <hr />
        If you would like to perform at the festival and have your own porch, please send us an email at <a href="mailto:hello@galtporchfest.ca">hello@galtporchfest.ca</a>
      </h4>
    </s.MessageWrapper>
  );
};

export default RegistrationFormMessage;
