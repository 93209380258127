import { useContext, useState, useEffect } from 'react';
import * as s from './FullScreenImage.style';

// Framer motion
import { motion, AnimatePresence } from 'framer-motion';

// Context
import AppContext from 'Context';

const FullScreenImage = () => {
  // State
  const [position, setPosition] = useState('static');

  // Context
  const context = useContext(AppContext);
  const { fullScreenImg, setFullScreenImg } = context;

  useEffect(() => {
    setTimeout(() => setPosition('absolute'), 300);
  }, [fullScreenImg]);

  const handleClose = () => {
    setFullScreenImg(null);
  };

  return (
    <AnimatePresence>
      {fullScreenImg && (
        <motion.nav
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.25 }}
        >
          <s.ImageContainer position={position}>
            <s.CloseIconContainer onClick={handleClose}>
              <s.CloseIcon src={'icons/close.svg'} />
            </s.CloseIconContainer>
            <s.ImageWrapper>
              <s.Image src={fullScreenImg} />
            </s.ImageWrapper>
          </s.ImageContainer>
        </motion.nav>
      )}
    </AnimatePresence>
  );
};

export default FullScreenImage;
