import * as s from './Act.style';
import { motion, AnimatePresence } from 'framer-motion';
import { useContext } from 'react';

import CoolButton from 'components/Button/Button';
import RegistrationFormMessage from 'components/RegistrationFormMessage/RegistrationFormMessage';

// Material UI
import Switch from '@material-ui/core/Switch';

// Context
import AppContext from 'Context';

const ActForm = () => {
  // Context
  const context = useContext(AppContext);
  const { actForm, setActForm, isMobile } = context;

  const handleChange = (e, switchElement) => {
    const newActForm = { ...actForm };

    if (e.target.name === 'phone' && (e.target.value.length === 3 || e.target.value.length === 7)) {
      newActForm[e.target.name] = e.target.value + '-';
    } else if (switchElement === 'lights') newActForm['lights'] = !actForm.lights;
    else if (switchElement === 'house') newActForm['hasOwnPorch'] = !actForm.hasOwnPorch;
    else newActForm[e.target.name] = e.target.value;

    setActForm(newActForm);
  };

  const handleKeyDown = (e) => {
    if ((actForm.phone.length === 5 || actForm.phone.length === 9) && e.keyCode === 8) {
      e.preventDefault();

      const newActForm = { ...actForm };
      newActForm['phone'] = actForm.phone.substr(0, actForm.phone.length - 2);
      setActForm(newActForm);
    }
    if ((actForm.phone.length === 4 || actForm.phone.length === 8) && e.keyCode === 8) {
      e.preventDefault();

      const newActForm = { ...actForm };
      newActForm['phone'] = actForm.phone.substr(0, actForm.phone.length - 2);
      setActForm(newActForm);
    }
  };

  return (
    <motion.nav initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.2 }}>
      <hr />
      <RegistrationFormMessage messageForType="performers" messageType="closed" />

      {/*  
      <form action="https://formsubmit.co/eugene@150ml.com" method="POST" id="act_form">
        <s.PorchFormWrapper>
          <s.PorchFormLeftSide>
            <input
              name="TYPE"
              defaultValue="PERFORMER"
              style={{ display: 'none' }}
              onChange={() => {
                return;
              }}
            />
            <s.SingleInput>
              <s.Label htmlFor="band_name">Musician / Band Name</s.Label>
              <s.Input autoComplete="off" id="band_name" name="band_name" required value={actForm.band_name} onChange={handleChange} />
            </s.SingleInput>

            <s.SingleInput>
              <s.Label htmlFor="contact_name">Contact Name</s.Label>
              <s.Input autoComplete="off" id="contact_name" name="contact_name" required value={actForm.contact_name} onChange={handleChange} />
            </s.SingleInput>

            <s.SingleInput>
              <s.Label htmlFor="phone">Phone (optional)</s.Label>
              <s.Input autoComplete="off" id="phone" maxLength={12} name="phone" value={actForm.phone} onChange={handleChange} onKeyDown={handleKeyDown} />
            </s.SingleInput>

            <s.SingleInput className="last-input">
              <s.Label htmlFor="email">Email</s.Label>
              <s.Input
                autoComplete="off"
                id="email"
                name="email"
                required
                // type='email'
                value={actForm.email}
                onChange={handleChange}
              />
            </s.SingleInput>

            <input type="hidden" name="_captcha" value="false" />
            <input type="hidden" name="_subject" value="New Porchfest submission!" />
            <input type="text" name="_honey" style={{ display: 'none' }} />
            <input type="hidden" name="_cc" value="priyankakhimasia@gmail.com" />
            <input type="hidden" name="_template" value="table" />
            <input type="hidden" name="_next" value="https://galtporchfest.ca/form-completed" />

            <input // Hack to include MaterialUI switches in FormSubmit
              name="is_electricity_needed"
              value={actForm.lights ? 'Yes' : 'No'}
              style={{ display: 'none' }}
              onChange={() => {
                return;
              }}
            />
            <input
              name="has_own_porch"
              value={actForm.hasOwnPorch ? 'Yes' : 'No'}
              style={{ display: 'none' }}
              onChange={() => {
                return;
              }}
            />

            {!isMobile && !actForm.hasOwnPorch && (
              <s.ElectricityWrapper checked={actForm.lights}>
                <s.ElectricityHeader>Do you require electricity access?</s.ElectricityHeader>
                <Switch
                  checked={actForm.lights}
                  color={'primary'}
                  // Change includes fake event
                  onChange={() => handleChange({ target: { name: null } }, 'lights')}
                />
                <s.LightBulb src={`icons/light_${actForm.lights ? 'on' : 'off'}.svg`} />
              </s.ElectricityWrapper>
            )}
          </s.PorchFormLeftSide>
          <s.PorchFormRightSide>
            <s.SingleInput rightSide>
              {isMobile && <s.Label htmlFor="act_description">Performatnce description&nbsp;</s.Label>}
              <s.ActDescription
                id="porch_form"
                name="act_description"
                placeholder="Instruments / Genre / Links etc."
                required
                rows={4}
                value={actForm.act_description}
                onChange={handleChange}
              />
            </s.SingleInput>

            <s.PorchAvailableWrapper checked={actForm.hasOwnPorch} hasOwnPorch={actForm.hasOwnPorch}>
              <s.PorchAvailableHeader>Do you already have a porch to perform? (must be in Galt)</s.PorchAvailableHeader>
              <Switch
                checked={actForm.hasOwnPorch}
                color={'primary'}
                // Change includes fake event
                onChange={() => handleChange({ target: { name: null } }, 'house')}
              />
              <s.House src={`icons/house-${actForm.hasOwnPorch ? 'green' : 'grey'}.png`} />
            </s.PorchAvailableWrapper>

            <AnimatePresence>
              {actForm.hasOwnPorch && (
                <motion.nav initial={{ opacity: 0, y: -15 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.35 }} exit={!isMobile && { opacity: 0, y: -15 }}>
                  <s.SingleInput rightSide>
                    <s.Label htmlFor="porch_address" rightSide>
                      Porch address
                    </s.Label>
                    <s.Input
                      autoComplete="off"
                      id="porch_address"
                      name="porch_address"
                      placeholder="ex. 1 Water Street"
                      required={actForm.hasOwnPorch}
                      rightSide
                      value={actForm.porch_address}
                      onChange={handleChange}
                    />
                  </s.SingleInput>
                </motion.nav>
              )}
            </AnimatePresence>

            {isMobile && !actForm.hasOwnPorch && (
              <s.ElectricityWrapper checked={actForm.lights}>
                <s.ElectricityHeader>Do you require electricity access?</s.ElectricityHeader>
                <Switch
                  checked={actForm.lights}
                  color={'primary'}
                  // Change includes fake event
                  onChange={() => handleChange({ target: { name: null } }, 'lights')}
                />
                <s.LightBulb src={`icons/light_${actForm.lights ? 'on' : 'off'}.svg`} />
              </s.ElectricityWrapper>
            )}
          </s.PorchFormRightSide>
        </s.PorchFormWrapper>

        <hr />

        <s.ButtonContainer>
          <CoolButton label={'SUBMIT'} />
        </s.ButtonContainer>
      </form>
      */}
    </motion.nav>
  );
};

export default ActForm;
