import { useRef, useContext, useLayoutEffect, useState } from 'react';
import * as s from './TwoColumnCarousel.style';

// Context
import AppContext from 'Context';

// Scroll booster
import ScrollBooster from 'scrollbooster';

const TwoColumnCarousel = ({ selectedUrls, allArtistsNames, shuffleArray }) => {
  // Context
  const context = useContext(AppContext);

  // State
  const [rows, setRows] = useState({ row1: [], row2: [] });

  // Refs
  const carouselWrapperRef = useRef();
  const viewportRef = useRef();
  const contentRef = useRef();

  // Initialize carousel scroll booster
  useLayoutEffect(() => {
    carouselWrapperRef.current = new ScrollBooster({
      viewport: viewportRef.current,
      content: contentRef.current,
      direction: 'horizontal',
      scrollMode: 'transform',
      textSelection: true,
      friction: 0.05,
      emulateScroll: true,
      dragDirectionTolerance: 99
    });
  });

  // Initialize photo arrays
  useLayoutEffect(() => {
    // Place all photos into one array
    const allPics = JSON.parse(JSON.stringify(selectedUrls));

    // Shuffle that array
    shuffleArray(allPics);

    // Split into two equal sized arrays
    const row1 = [];
    const row2 = [];
    const midPoint = Math.ceil(allPics.length / 2);
    allPics.forEach((img, index) => {
      if (index + 1 <= midPoint) row1.push(img);
      else row2.push(img);
    });

    // Set rows
    setRows({ row1, row2 });
  }, [selectedUrls]);

  const handleZoomClick = (e, imgUrl) => {
    e.preventDefault();
    const fullScreenUrl = imgUrl.replace('thumbnails', 'full-size');
    console.log(fullScreenUrl);
    context.setFullScreenImg(fullScreenUrl);
  };

  const getName = (url) => {
    let displayName = allArtistsNames.find(
      (artistName) => url.indexOf(artistName) > -1
    );
    return displayName;
  };

  const Row = ({ arr }) => {
    return arr.map((url, index) => {
      return (
        <s.SliderImageWrapper>
          <s.SearchIconContainer onClick={(e) => handleZoomClick(e, url)}>
            <s.SearchIcon src={'icons/search.svg'} />
          </s.SearchIconContainer>
          <s.ImageWrapper className="img-wrapper">
            <s.SliderImage src={url} />
          </s.ImageWrapper>
          <s.SliderImageDataWrapper className="data-wrapper">
            <s.ImageName onClick={(e) => handleZoomClick(e, url)}>
              {getName(url)}
            </s.ImageName>
            <s.DarkOverlay />
          </s.SliderImageDataWrapper>
        </s.SliderImageWrapper>
      );
    });
  };

  return (
    <s.CarouselWrapper ref={carouselWrapperRef}>
      <s.Viewport ref={viewportRef}>
        <s.Content ref={contentRef}>
          <s.RowOne>
            <Row arr={rows.row1} />
          </s.RowOne>
          <s.RowTwo>
            <Row arr={rows.row2} />
          </s.RowTwo>
        </s.Content>
      </s.Viewport>
    </s.CarouselWrapper>
  );
};

export default TwoColumnCarousel;
