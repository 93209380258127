import styled from '@emotion/styled';

export const App = styled.div`
  background-color: #2b3240;
  position: relative;
  background-image: linear-gradient(315deg, #48546199 0%, #48546199 74%),
    url(img/guitars.jpg);
  background-repeat: repeat;
  background-position: fixed;
`;

export const MainView = styled.div`
  ${(p) => !p.isCurrentPageGallery && 'max-width: 1200px'};
  background-color: #2b3240cc;
  min-height: calc(100vh - 180px);
  margin: 74px auto 0;
  padding: 26px 40px 40px;
  color: #fff;
  font-family: 'Yomogi', cursive;

  overflow: auto;
  overflow-x: hidden;

  // ------ iPad Pro
  @media (max-width: 1366px) {
    max-width: 100%;
    background-color: #2b3240b2;
    transition: 0.3s ease;
  }

  // ------ Phone
  @media (max-width: 1024px) {
    margin: 55px auto 0;
    padding: 26px 30px 40px;
    min-height: calc(100vh - 120px);
  }
`;

export const DarkOverlay = styled.div`
  top: 55px;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  position: fixed;
  z-index: 200;
  transition: background 0.4s;
  background: ${(p) => (p.show ? '#000000;' : 'transparent')};
  z-index: 8;
  ${(p) => !p.show && 'pointer-events: none'};

  // ------ Phone
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const Footer = styled.div`
  position: fixed;
  bottom: 0;
  height: 56px;
  box-shadow: 0px 0px 14px #ff7a46;
  width: 100%;
  background-color: #2b3240;
  color: #ff7a46;
  font-weight: 900;
  font-family: 'Indie Flower', cursive;
  font-size: 110%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  letter-spacing: 2px;

  // ------ Phone
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Email = styled.a`
  font-weight: 100;
  margin-left: 40px;
  text-decoration: none;
  color: #ff7a46;
`;

export const SocialAndCopyright = styled.div`
  display: flex;
  align-items: center;
`;

export const FBIcon = styled.img`
  height: 28px;
  border-radius: 8px;
  cursor: pointer;
`;

export const IGIcon = styled.img`
  height: 29px;
  cursor: pointer;
  margin-right: 10px;
`;

export const CopyrightMessage = styled.p`
  font-weight: 100;
  margin-right: 50px;
  margin-left: 30px;
`;
