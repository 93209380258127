import styled from '@emotion/styled';

export const SignupContainer = styled.div`
    hr {
        margin: 30px;
        border: 0;
        height: 1px;
        background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.75),
            rgba(255, 255, 255, 0)
        );
    }

    h3 {
        font-weight: 100;
        text-align: center;
    }
`

export const Header = styled.h1`
    font-size: 160%;
    font-weight: 100;
    text-align: center;
    letter-spacing: 1px;
    font-family: 'Indie Flower', cursive;
    font-weight: 100;
    margin: 0 0 14px
`

export const DateDesktop = styled.p`
    display: none;
    text-align: center;
    color: #68daff;
    letter-spacing: 1px;
    font-weight: 900;
    font-size: 18px;
    padding: 10px 20px 18px;
    background-color: #2b3240;
    border-radius: 15px;
    box-shadow: 0 0 10px #ff7a46;
    width: 400px;
    margin: 20px auto 0;

    @media (min-width: 1025px) {
        display: block
    }
`

export const DateMobile = styled.p`
    display: none;
    text-align: center;
    color: #68daff;
    letter-spacing: 1px;
    font-weight: 900;
    margin: 0 0 24px;
    padding: 10px 20px 18px;
    background-color: #2b3240;
    border-radius: 15px;
    box-shadow: 0 0 10px #ff7a46;

    @media (max-width: 1024px) {
        display: block
    }
`

export const SignupButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 520px;
    margin: 0 auto;

    // ------ Phone
    @media (max-width: 1024px) {
        width: 100%;
        height: 120px;
        flex-direction: column;
        align-items: center
    }
`

export const SignupWrapper = styled.div`
    font-family: 'Indie Flower', cursive;
    font-size: 19px;
    background: ${p => p.selected ? '#ff7a46' : '#2b3240'};
    color: ${p => p.selected ? '#000' : '#fff'};
    cursor: pointer;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
    align-items: center;
    width: 220px;
    transition: all .15s ease-in;
    border: 2px solid ${p => p.selected ? '#ff7a46;' : '#8e482c'};

    &:hover {
        ${p => !p.selected && 'background: #374154;'};
        border: 2px solid #ff7a46
    }
`

export const IconWrapper = styled.div`
    width: 36px;
    display: flex;
    align-items: center;
    padding: 6px;
    background: #2b3240;
    transition: background .15s ease-in;

    ${p => p.hovered && !p.selected && 'background: #374154;'}
`

export const Icon = styled.img`
    height: 34px;
    color: #fff;
    margin-right: 20px
`

export const FormWrapper = styled.div`
    margin-top: 20px;
    padding: 0 6px;
`