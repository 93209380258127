import styled from '@emotion/styled';

export const Header = styled.h1`
    font-size: 160%;
    text-align: center;
    letter-spacing: 1px;
    font-family: 'Indie Flower', cursive;
    font-weight: 100;
    margin: 0 0 14px
`

export const Joke = styled.h3`
    font-weight: 100;
    margin: 0 auto;
    padding: 20px 40px;
    width: 600px;
    background: #2b3240CC;
    border: 1px solid grey;
    border-radius: 10px;
    margin-top: 40px;
    
    // ------ Phone
    @media (max-width: 1024px) {
        width: 100%;
        box-sizing: border-box;
        padding: 10px 20px;
    }
`