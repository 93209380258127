export const polygonCoords = [
  { lat: 43.365966, lng: -80.33004 },
  { lat: 43.360597, lng: -80.329583 },
  { lat: 43.35986, lng: -80.332088 },
  { lat: 43.354005, lng: -80.329975 },
  { lat: 43.351859, lng: -80.327548 },
  { lat: 43.353355, lng: -80.315477 },
  { lat: 43.3684, lng: -80.3155 }
];

export const markers = [
  {
    address: '31 Brant Road North',
    position: { lat: 43.360449299946936, lng: -80.32254563573805 },
    isWashroom: false,
    act: [
      {
        title: 'Silvia Dee & the Boyfriend',
        time: '12:00 - 1:00pm'
      }
    ]
  },
  {
    address: '30 Brant Road North',
    position: { lat: 43.36039757111754, lng: -80.32313526781523 },
    isWashroom: false,
    act: [
      {
        title: 'Father and Son Reunion',
        time: '1:00 - 2:00pm'
      }
    ]
  },
  {
    address: 'Ferguson Cottage / 37 Grant Avenue South',
    position: { lat: 43.35693292133257, lng: -80.31702160211601 },
    isWashroom: false,
    act: [
      {
        title: 'Liam Hoff and the Heavy Hitters',
        time: '1:00 - 2:00pm'
      },
      {
        title: 'Tea for Three',
        time: '2:30 - 3:30pm'
      }
    ]
  },
  {
    address: '33 Brant Road North',
    position: { lat: 43.36062849965907, lng: -80.322502135757 },
    isWashroom: false,
    act: [
      {
        title: 'Onion Honey',
        time: '2:00 - 3:00pm'
      }
    ]
  },
  {
    address: '36 Grant Street',
    position: { lat: 43.36671996254132, lng: -80.32762604568288 },
    isWashroom: false,
    act: [
      {
        title: 'Terns',
        time: '2:30 - 3:30pm'
      }
    ]
  },
  {
    address: '11 Brant Pl',
    position: { lat: 43.36212746780248, lng: -80.3232516006297 },
    isWashroom: false,
    act: [
      {
        title: 'Tyler Montague March',
        time: '3:00 - 4:00pm'
      }
    ]
  },
  {
    address: '32 Brant Road North',
    position: { lat: 43.360561795233174, lng: -80.32315972516011 },
    isWashroom: false,
    act: [
      {
        title: 'The Craig Robertson Band',
        time: '3:00 - 4:00pm'
      }
    ]
  },
  {
    address: '40 Hillcrest Drive',
    position: { lat: 43.35780978659409, lng: -80.33415923133408 },
    isWashroom: false,
    act: [
      {
        title: 'The Rewind Line',
        time: '3:00 - 4:00pm'
      }
    ]
  },
  {
    address: '91 Blair Road',
    position: { lat: 43.363745470530574, lng: -80.32330470335465 },
    isWashroom: false,
    act: [
      {
        title: 'Ekogemah',
        time: '3:00 - 4:00pm'
      }
    ]
  },
  {
    address: '131 Blair Road',
    position: { lat: 43.365112021068995, lng: -80.3251783855636 },
    isWashroom: false,
    act: [
      {
        title: 'Beige Shelter',
        time: '3:30 - 4:30pm'
      }
    ]
  },
  {
    address: '16 Byng Ave',
    position: { lat: 43.357176886646975, lng: -80.32077708900623 },
    isWashroom: false,
    act: [
      {
        title: 'Kyle Moning',
        time: '3:30 - 4:30pm'
      }
    ]
  },
  {
    address: '2 Lansdowne Rd South',
    position: { lat: 43.358116481535674, lng: -80.32165821784223 },
    isWashroom: false,
    act: [
      {
        title: 'Craig McNair',
        time: '3:30 - 4:30pm'
      }
    ]
  },
  {
    address: '29 Brant Road North',
    position: { lat: 43.36032430702562, lng: -80.32253300123851 },
    isWashroom: false,
    act: [
      {
        title: 'Dan Badger',
        time: '4:00 - 5:00pm'
      }
    ]
  },
  {
    address: '33 Hillcrest Drive',
    position: { lat: 43.357484291432016, lng: -80.33335127634072 },
    isWashroom: false,
    act: [
      {
        title: 'Charlie Mills',
        time: '4:00 - 5:00pmd'
      }
    ]
  },
  {
    address: '61 Barrie St',
    position: { lat: 43.35514344572189, lng: -80.32502083893714 },
    isWashroom: false,
    act: [
      {
        title: 'Gregory Boivin',
        time: '4:00 - 5:00pm'
      }
    ]
  },
  {
    address: '97 Blair Road',
    position: { lat: 43.36368487072517, lng: -80.32405750211863 },
    isWashroom: false,
    act: [
      {
        title: 'Acoustic JAK',
        time: '4:30 - 5:30pm'
      }
    ]
  },
  {
    address: '37 Lansdowne Road North',
    position: { lat: 43.36053233179991, lng: -80.32108049590036 },
    isWashroom: false,
    act: [
      {
        title: 'The Harmony Riders',
        time: '4:30 - 5:30pm'
      }
    ]
  },
  {
    address: '24 Brant Road North',
    position: { lat: 43.359913580319876, lng: -80.32336232910623 },
    isWashroom: false,
    act: [
      {
        title: 'Between Names',
        time: '5:00 - 6:00pm'
      }
    ]
  },
  {
    address: '68 Hillsboro Drive',
    position: { lat: 43.35409162322719, lng: -80.34064795002445 },
    isWashroom: false,
    act: [
      {
        title: 'Zenen',
        time: '5:00 - 6:00pm'
      }
    ]
  },
  {
    address: '73 Water Street North',
    position: { lat: 43.361750485537826, lng: -80.31471957058788 },
    isWashroom: false,
    act: [
      {
        title: 'Naked Bear DJ',
        time: '5:00 - 6:30pm'
      }
    ]
  },
  {
    address: '92 Wentworth Avenue',
    position: { lat: 43.36029912102086, lng: -80.32442760335486 },
    isWashroom: false,
    act: [
      {
        title: 'Cambridge Symphony Orchestra',
        time: '6:00 - 7:00pm'
      }
    ]
  },

  // ----------------------------------------------
  {
    address: '',
    time: 'by the tennis courts',
    title: 'Washrooms / Victoria Park',
    position: { lat: 43.35996, lng: -80.32615 },
    isWashroom: true
  },
  {
    address: '',
    time: 'inside Old Galt Bottle Shop building',
    title: 'Washrooms / 135 George Street N',
    position: { lat: 43.36411477025209, lng: -80.31821484482559 },
    isWashroom: true
  },
  {
    address: '',
    time: '',
    title: 'Washrooms / Shell Gas Station',
    position: { lat: 43.35214, lng: -80.32427 },
    isWashroom: true
  },
  {
    address: '',
    time: 'in the hallway, across from Melville Café',
    title: 'Washrooms / Waterloo Architecture School',
    position: { lat: 43.3585, lng: -80.31656 },
    isWashroom: true
  }
];

export const mapStyle = [
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on'
      }
    ]
  },
  {
    featureType: 'transit.station',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  }
];
