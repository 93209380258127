import styled from '@emotion/styled';

export const NavbarContainer = styled.div`
  background-color: #2b3240;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  height: 74px;
  box-shadow: 0px 0px 14px #ff7a46;

  // ------ Phone
  @media (max-width: 1024px) {
    height: 62px;
  }
`;

// Desktop ------------------------------
export const NavbarWrapperDesktop = styled.div`
  display: flex;
  height: inherit;
  width: 1280px;
  margin: 0 auto !important;
  justify-content: center;

  // ------ iPad Pro
  @media (max-width: 1200px) {
    width: 1024px;
  }

  // ------ Phone
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Logo = styled.img`
  height: 62px;
  cursor: pointer;
  margin: 4px 50px 0;

  // ------ iPad Pro
  @media (max-width: 1200px) {
    margin: 4px 30px 0;
  }

  // ------ Phone
  @media (max-width: 1024px) {
    height: 55px;
    margin: 0;
    padding-left: 20px;
  }
`;

export const MenuRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #5fcbee;
  font-family: 'Indie Flower', cursive;
  font-size: 125%;
  height: 70px;
  transition: 0.2s ease-in;

  // ------ iPad Pro
  @media (max-width: 1200px) {
    width: 416px;
  }
`;

export const MenuItemWrapper = styled.div`
  width: 132px;
  text-align: center;
`;

export const MenuItem = styled.p`
  cursor: pointer;
  transition: 0.2s ease-in;
  position: relative;
  padding: 0.1em 0;
  display: inline-block;

  ${(p) =>
    p.isSelected &&
    `
        color: #fff;
        letter-spacing: 1px;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0.1em;
            background-color: #ff7a46;
            opacity: 1;
            transform: translate3d(0, 0.2em, 0);
        }
    `};

  ${(p) =>
    !p.isSelected &&
    `
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0.1em;
            background-color: #ff7a46;
            opacity: 0;
            transition: opacity 300ms, transform 300ms;
        }
        
        &:hover::after,
        &:focus::after {
            opacity: 1;
            transform: translate3d(0, 0.2em, 0);
        }
    `}
`;

// Mobile ------------------------------
export const NavbarWrapperMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  background-color: #2b3240;
  position: relative;

  // ------ Phone
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const Hamburger = styled.div`
  cursor: pointer;
  padding-right: 20px;
`;

export const Bar = styled.div`
  width: 30px;
  height: 4px;
  border-radius: 10px;
  background-color: #fff;
  transition: 0.3s;
`;

export const MenuItemWrapperMobile = styled.div`
  position: fixed;
  z-index: 9;
  height: 300px;
  top: ${(p) => (p.isOpen ? '55px' : '-345px')};
  width: 100%;
  transition: top 0.5s;
  box-shadow: 0px 0px 14px #5d6c89;

  // ------ Phone
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const MenuItemMobile = styled.div`
  margin: 0;
  padding-left: 0;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font-size: 140%;
  transition: all 0.3s;
  width: 100%;
  z-index: 1 !important;
  font-family: 'Indie Flower', cursive;
  cursor: pointer;
  font-size: 115%;
  letter-spacing: 1px;
  background-color: ${(p) => {
    let color;
    switch (p.index) {
      case 0:
        color = '#4b5770';
        break;
      case 1:
        color = '#465168';
        break;
      case 2:
        color = '#404b60';
        break;
      case 3:
        color = '#3d475b';
        break;
      case 4:
        color = '#384154';
        break;
      case 5:
        color = '#313949';
        break;
      case 6:
        color = '#29303d';
        break;
      case 7:
        color = '#232935';
        break;
      default:
        break;
    }
    return color;
  }};
`;
