import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Components
import Home from 'components/pages/Home/Home';
import Contact from 'components/pages/Contact/Contact';
import Faq from 'components/pages/Faq/Faq';
import Performers from 'components/pages/Performers/Performers';
import Media from 'components/pages/Media/Media';
import Gallery from 'components/pages/Gallery/Gallery';
import Schedule from 'components/pages/Schedule/Schedule';
import Signup from 'components/pages/Signup/Signup';
import Thanks from 'components/pages/Thanks/Thanks';
import NotFound from 'components/pages/NotFound/NotFound';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/faq" component={Faq} />
      <Route exact path="/performers" component={Performers} />
      <Route exact path="/media" component={Media} />
      <Route exact path="/gallery" component={Gallery} />
      <Route exact path="/schedule" component={Schedule} />
      <Route exact path="/sign-up" component={Signup} />
      <Route exact path="/form-completed" component={Thanks} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
