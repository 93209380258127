import styled from '@emotion/styled';

export const ArtistContentWrapper = styled.div`
    display: flex;
       
    // ------ Phone
    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
    }
`

export const ArtistImgWrapper = styled.div`
    max-width: 350px;
    min-width: 150px;
    margin-right: 60px;
    
    // ------ Phone
    @media (max-width: 1024px) {
        margin-right: 0
    }
`

export const ArtistImg = styled.img`
    width: 100%;
    box-shadow: 0 0 10px #d1d1d1;
    border-radius: 10px;
`

export const DescriptionWrapper = styled.div`
    width: 80%;
        
    // ------ Phone
    @media (max-width: 1024px) {
        width: 100%;
        margin-top: 20px;
    }
`

export const SocialIconsWrapper = styled.div``

export const Icon = styled.img`
    width: 40px;
    margin-right: 14px;
    cursor: pointer;
`