import styled from '@emotion/styled';

export const ScheduleWrapper = styled.div`
  hr {
    margin: 15px 0;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0));
  }
`;

export const Header = styled.h1`
  font-size: 160%;
  text-align: center;
  letter-spacing: 1px;
  font-family: 'Indie Flower', cursive;
  font-weight: 100;
  margin: 0 0 14px;
`;

export const DateDesktop = styled.p`
  display: none;
  text-align: center;
  color: #68daff;
  letter-spacing: 1px;
  font-weight: 900;
  font-size: 18px;
  padding: 10px 20px 18px;
  background-color: #2b3240;
  border-radius: 15px;
  box-shadow: 0 0 10px #ff7a46;
  width: 600px;
  margin: 20px auto;

  @media (min-width: 1024px) {
    display: block;
  }
`;

export const DateMobile = styled.p`
  display: none;
  text-align: center;
  color: #68daff;
  letter-spacing: 1px;
  font-weight: 900;
  margin: 0 0 24px;
  padding: 10px 20px 18px;
  background-color: #2b3240;
  border-radius: 15px;
  box-shadow: 0 0 10px #ff7a46;

  @media (max-width: 1024px) {
    display: block;
  }
`;

export const MapWrapper = styled.div`
  width: 1024px;
  height: ${(p) => (p.isPhone ? '400px' : '500px')};
  box-shadow: 0px 0px 14px #ff7a46;
  border-radius: 10px;
  margin: 0 auto 30px;

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #bdc3c7;
    margin: 1em 0;
    padding: 0;
  }

  // ------ Phone
  @media (max-width: 1090px) {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const InfoWindowWrapper = styled.div`
  font-family: 'Yomogi', cursive;
  text-align: center;
  padding: 10px;
`;

export const InfoWindowHeader = styled.h1`
  font-family: Beon, serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #ff7a46;
  margin: 18px 0 0;
  ${(p) =>
    p.isWashroom &&
    `
        font-size: 120%;
        color: #2980b9;
        letter-spacing: 1px;
        margin: 0;
    `}
  ${(p) => p.isParking && 'color: #636e72 !important'}
`;

export const InfoWindowText = styled.p`
  font-size: 120%;
  margin: 0;
  color: #333;

  ${(p) =>
    p.isAddress &&
    `
        letter-spacing: 1px;
        font-weight: 600;
        color: #636e72;
    `}
`;

export const Icon = styled.img`
  height: 28px;
`;

export const PerformersWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Performer = styled.div`
  margin: 0 0 10px;
  cursor: pointer;
  text-align: center;
  transition: 0.1s ease-in-out;

  &:hover {
    h1 {
      color: #ff7a46;
      transition: 0.1s ease-in-out;
      text-shadow: 0 0 7px #ff7a46, 0 0 21px #ff7a46, 0 0 92px #7f8c8d, 0 0 102px #7f8c8d, 0 0 151px #7f8c8d;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: 100;
  }

  h3 {
    color: #ff7a46;
    margin-bottom: 0;
  }

  h1 {
    margin: 0 0 8px;
    font-family: Beon, serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    transition: color 0.1s ease-in;
  }

  h2 {
    margin: 0;
    font-size: 130%;
    font-style: italic;
    color: #b2bec3;
  }

  h4 {
    margin: 0 0 12px;
    letter-spacing: 1px;
    color: #ff7a46;
    font-size: 120%;
  }
`;
