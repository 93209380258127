import * as s from './SelectedPerformer.style'

import { useContext } from 'react'

// Context
import AppContext from 'Context';


const SelectedPerformer = () => {
    // Context
    const context = useContext(AppContext);
    const { selectedPerformer } = context;


    return selectedPerformer.name && (
        <>
            <h1>{selectedPerformer.name}</h1>
            <s.ArtistContentWrapper>
                <s.ArtistImgWrapper>
                    <s.ArtistImg src={selectedPerformer.photo} />
                </s.ArtistImgWrapper>
                <s.DescriptionWrapper>
                    <div dangerouslySetInnerHTML={{__html: selectedPerformer.bio}} />
                    <br />
                    <b>{<>Genre:&nbsp;</>}</b>{selectedPerformer.genre}
                    <br /><br />
                    <s.SocialIconsWrapper>
                        {selectedPerformer.links.map(link => <s.Icon key={link.url} src={link.icon} onClick={() => window.open(link.url, '_blank')} />)}
                    </s.SocialIconsWrapper>
                </s.DescriptionWrapper>
            </s.ArtistContentWrapper>
        </>
    )
}

export default SelectedPerformer