import * as s from './Performers.style';
import { motion } from 'framer-motion';

import { currentPerformersList, allPerformersFromAllTime } from 'Performers';
import { useContext } from 'react';

// Context
import AppContext from 'Context';

import SelectedPerformer from './SelectedPerformer';

const Performers = () => {
  // Context
  const context = useContext(AppContext);
  const { handleSelectPerformer, selectedPerformer } = context;

  const handleArtistClick = (name) => {
    handleSelectPerformer(name);

    const scrollingElement = document.scrollingElement || document.body;
    scrollingElement.scrollTop = scrollingElement.scrollHeight;
  };

  const renderNameColumn = (col) =>
    col.map((artist) => {
      return (
        <s.Name key={artist.name} onClick={() => handleArtistClick(artist.name)} isSelected={selectedPerformer.name === artist.name}>
          {artist.name}
        </s.Name>
      );
    });

  return (
    <motion.nav initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.2 }}>
      <s.PerformersContainer>
        <s.Header>
          <span style={{ color: '#ff7a46' }}>P</span>erformers
        </s.Header>

        <s.IntroMessage>
          {/* Please come back around July for the <b>2024</b> lineup. */}
          Here is our lineup of artists and bands for <b>2024</b>:
        </s.IntroMessage>
        <s.NamesWrapper>
          <s.NameCol>{renderNameColumn(currentPerformersList.col1)}</s.NameCol>
          <s.NameCol>{renderNameColumn(currentPerformersList.col2)}</s.NameCol>
          <s.NameCol>{renderNameColumn(currentPerformersList.col3)}</s.NameCol>
        </s.NamesWrapper>
        <s.HrWrapper>
          <hr />
        </s.HrWrapper>

        <s.IntroMessage>Here are some of our past performers:</s.IntroMessage>
        <s.NamesWrapper>
          <s.NameCol>{renderNameColumn(allPerformersFromAllTime.col1)}</s.NameCol>
          <s.NameCol>{renderNameColumn(allPerformersFromAllTime.col2)}</s.NameCol>
          <s.NameCol>{renderNameColumn(allPerformersFromAllTime.col3)}</s.NameCol>
        </s.NamesWrapper>
        <s.HrWrapper>
          <hr />
        </s.HrWrapper>
        <s.ArtistWrapper>
          <SelectedPerformer />
        </s.ArtistWrapper>
      </s.PerformersContainer>
    </motion.nav>
  );
};

export default Performers;
