import * as s from './Thanks.style';
import { motion } from 'framer-motion';

const Thanks = () => {
  return (
    <motion.nav initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.2 }}>
      <s.Header>
        <span style={{ color: '#ff7a46' }}>F</span>orm <span style={{ color: '#ff7a46' }}>S</span>ubmitted
      </s.Header>
      <s.MessageWrapper>
        <s.ThanksIcon src="icons/thanks.png" />
        <s.Message>
          Thank you for you interest in participating in Galt Porchfest 2024!
          <br />
          We will review your form and will get back to you shortly.
        </s.Message>
      </s.MessageWrapper>
    </motion.nav>
  );
};

export default Thanks;
