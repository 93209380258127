export const allImages = [
  {
    name: 'Aldara',
    numberOfImages: 7
  },
  {
    name: 'Brian Gardiner',
    numberOfImages: 3
  },
  {
    name: 'Ceadda Casarin',
    numberOfImages: 1
  },
  {
    name: 'Chandler McQuade with Kat',
    numberOfImages: 4
  },
  {
    name: 'Charlie Mills',
    numberOfImages: 2
  },
  {
    name: 'Connor Roy',
    numberOfImages: 2
  },
  {
    name: 'Craig Robertson & Jeff Dunk',
    numberOfImages: 5
  },
  {
    name: 'Craig McNair',
    numberOfImages: 1
  },
  {
    name: 'Miscellaneous',
    numberOfImages: 19
  },
  {
    name: 'Dan Badger',
    numberOfImages: 10
  },
  {
    name: 'Ekogemah',
    numberOfImages: 7
  },
  {
    name: 'Eugene Costov',
    numberOfImages: 8
  },
  {
    name: 'Father and Son Reunion',
    numberOfImages: 3
  },
  {
    name: 'Josh Cairney',
    numberOfImages: 3
  },
  {
    name: 'Kate Ruckus',
    numberOfImages: 2
  },
  {
    name: 'Kyle Moning',
    numberOfImages: 2
  },
  {
    name: 'Liam Hoff and the Heavy Hitters',
    numberOfImages: 4
  },
  {
    name: "McLovin' & Sarah McGrath",
    numberOfImages: 2
  },
  {
    name: 'Naked Bear DJ',
    numberOfImages: 1
  },
  {
    name: 'Neil Light',
    numberOfImages: 6
  },
  {
    name: 'Onion Honey',
    numberOfImages: 9
  },
  {
    name: 'Pay No Mind',
    numberOfImages: 2
  },
  {
    name: 'The Rewind Line',
    numberOfImages: 1
  },
  {
    name: 'Sussy Baka',
    numberOfImages: 19
  },
  {
    name: 'Tea for Three',
    numberOfImages: 5
  },
  {
    name: 'Terns',
    numberOfImages: 4
  },
  {
    name: 'The Boys & I',
    numberOfImages: 4
  },
  {
    name: 'Two by Six',
    numberOfImages: 4
  },
  {
    name: 'Tyler Montague March',
    numberOfImages: 2
  },
  {
    name: 'Wendy Bouie & David Flowers',
    numberOfImages: 3
  },
  {
    name: 'Zook & Legkicks',
    numberOfImages: 6
  }
];
