import styled from '@emotion/styled';

export const HomeContainer = styled.div`
  hr {
    margin: 30px;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0));
  }
`;

export const Header = styled.h1`
  font-size: 160%;
  text-align: center;
  letter-spacing: 1px;
  font-family: 'Indie Flower', cursive;
  font-weight: 100;
  margin: 0 0 14px;

  // ------ Phone
  @media (max-width: 1024px) {
    font-size: 160%;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  // ------ Phone
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
`;

export const ImgLogo = styled.img`
  width: 250px;
  height: auto;
  box-shadow: 0 0 10px #2b3240;
  border-radius: 50px;
  border: 6px solid #2b3240;
  margin: 0 auto 20px;

  // ------ Phone
  @media (max-width: 1024px) {
    width: 75%;
    max-width: 250px;
    height: auto;
    border-radius: 15px;
  }
`;

export const DateDesktop = styled.p`
  display: none;
  text-align: center;
  color: #68daff;
  letter-spacing: 1px;
  font-weight: 900;
  font-size: 18px;
  padding: 10px 20px 18px;
  background-color: #2b3240;
  border-radius: 15px;
  box-shadow: 0 0 10px #ff7a46;
  width: 400px;
  margin: 20px auto 0;

  @media (min-width: 1024px) {
    display: block;
  }
`;

export const DateMobile = styled.p`
  display: none;
  text-align: center;
  color: #68daff;
  letter-spacing: 1px;
  font-weight: 900;
  margin: 0 0 24px;
  padding: 10px 20px 18px;
  background-color: #2b3240;
  border-radius: 15px;
  box-shadow: 0 0 10px #ff7a46;

  @media (max-width: 1024px) {
    display: block;
  }
`;

export const Text = styled.p`
  margin: 0 0 0 60px;
  line-height: 30px;
  font-size: 16px;

  // ------ Phone
  @media (max-width: 1024px) {
    margin: 20px 0 0;
  }
`;

export const ButtonContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
  margin-top: 30px;
`;

export const ImgFromFestWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  row-gap: 16px;
  flex-flow: row wrap;
  max-width: 100%;
  margin: 36px 0 60px;
  height: auto;

  // ------ Phone
  @media (max-width: 1024px) {
    margin: 36px 0 20px;
  }
`;

export const ImgFromFest = styled.img`
  display: block;
  max-height: 300px;
  border-radius: 10px;
  border: 1px solid grey;
  box-shadow: 0 0 10px #2b3240;
  max-width: 100%;
  object-fit: contain;
`;

export const SponsorsBox = styled.div`
  display: flex;
  flex-direction: column;

  h5 {
    margin: 12px 0;
    text-align: center;

    @media (max-width: 1024px) {
      margin: 10px 0;
      text-align: center;
    }
  }
`;

export const SponsorImg = styled.img`
  box-shadow: 0 0 10px #2b3240;
  border-radius: 4px;
  cursor: pointer;
  max-height: ${(p) => (p.isMobile ? '90px' : '65px')};
`;

export const ScheduleLink = styled.div`
  font-weight: 100;
  font-size: 16px;
  margin-top: 12px;

  a {
    color: #ff7a46;
  }
`;
