import styled from '@emotion/styled';

export const ImageContainer = styled.div`
  position: ${(p) => p.position};
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: black;
  z-index: 20;
`;

export const ImageWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  max-height: 100%;
`;

export const CloseIconContainer = styled.span`
  position: fixed;
  top: 22.5pt;
  right: 22.5pt;
  width: 30pt;
  height: 30pt;
  cursor: pointer;
  border-radius: 100%;
  z-index: 5;
  transition: all 200ms linear;
  display: flex;
  justify-content: center;
  padding: 8px;
  align-items: center;
`;

export const CloseIcon = styled.img`
  height: 26px;
`;
