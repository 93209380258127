import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as s from './Navbar.style';

// Context
import AppContext from 'Context';

const Navbar = () => {
  const history = useHistory();

  // State
  const [selected, setSelected] = useState('');

  // Context
  const context = useContext(AppContext);
  const { currentPath, isNavbarOpenMobile, setIsNavbarOpenMobile } = context;

  // Effects
  // Set selected header item based on URL pathname
  useEffect(() => {
    if (currentPath !== '/') {
      let selectedItem = currentPath.substring(1);
      if (currentPath.indexOf('form-completed') > -1) selectedItem = 'sign-up';
      setSelected(selectedItem);
    } else setSelected('/');
  }, [currentPath]);

  const menuItemsLeft = [
    { name: 'Home', url: '/' },
    { name: 'Sign Up', url: 'sign-up' },
    { name: 'Gallery', url: 'gallery' },
    { name: 'Media', url: 'media' }
  ];
  const menuItemsRight = [
    { name: 'Schedule', url: 'schedule' },
    { name: 'Performers', url: 'performers' },
    { name: 'Contact', url: 'contact' },
    { name: 'F A Q', url: 'faq' }
  ];
  const menuItemsCombined = menuItemsLeft.concat(menuItemsRight);

  const handleClick = (menuItem) => {
    setSelected(menuItem);
    history.push(menuItem);
  };

  return (
    <s.NavbarContainer>
      {/* Mobile Navbar ---------------------------------------- */}
      <s.NavbarWrapperMobile>
        <s.Logo src={'img/logo_navbar.png'} onClick={() => handleClick('/')} />
        <s.Hamburger onClick={() => setIsNavbarOpenMobile(!isNavbarOpenMobile)}>
          <s.Bar
            style={{
              transform: isNavbarOpenMobile
                ? 'translate(0px, 9px) rotate(-45deg)'
                : ''
            }}
          />
          <s.Bar
            style={{ margin: '5px 0', opacity: isNavbarOpenMobile ? 0 : 1 }}
          />
          <s.Bar
            style={{
              transform: isNavbarOpenMobile
                ? 'translate(0px, -9px) rotate(45deg)'
                : ''
            }}
          />
        </s.Hamburger>
      </s.NavbarWrapperMobile>

      <s.MenuItemWrapperMobile isOpen={isNavbarOpenMobile}>
        {menuItemsCombined.map((item, idx) => (
          <s.MenuItemMobile
            key={item.name}
            isSelected={item.url === selected}
            index={idx}
            onClick={() => {
              handleClick(item.url);
              setIsNavbarOpenMobile(false);
            }}
          >
            {item.name}
          </s.MenuItemMobile>
        ))}
      </s.MenuItemWrapperMobile>
      {/* Desktop Navbar ---------------------------------------- */}
      <s.NavbarWrapperDesktop>
        <s.MenuRow>
          {menuItemsLeft.map((item) => (
            <s.MenuItemWrapper key={item.name}>
              <s.MenuItem
                isSelected={item.url === selected}
                onClick={() => handleClick(item.url)}
              >
                {item.name}
              </s.MenuItem>
            </s.MenuItemWrapper>
          ))}
        </s.MenuRow>
        <s.Logo src={'img/logo_navbar.png'} onClick={() => handleClick('/')} />
        <s.MenuRow>
          {menuItemsRight.map((item) => (
            <s.MenuItemWrapper key={item.name}>
              <s.MenuItem
                isSelected={item.url === selected}
                onClick={() => handleClick(item.url)}
              >
                {item.name}
              </s.MenuItem>
            </s.MenuItemWrapper>
          ))}
        </s.MenuRow>
      </s.NavbarWrapperDesktop>
    </s.NavbarContainer>
  );
};

export default Navbar;
