import styled from '@emotion/styled';

export const FaqContainer = styled.div`
    position: relative;
    margin-bottom: 40px
`

export const Header = styled.h1`
    font-size: 160%;
    text-align: center;
    letter-spacing: 1px;
    font-family: 'Indie Flower', cursive;
    font-weight: 100;
    margin: 0 0 14px
`

export const QuestionWrapper = styled.div`
    margin-bottom: 10px;
    padding-left: 10px;
    ${p => p.isOpen && `background-color: #2b3240E6; padding-bottom: 10px; cursor: pointer`};
    transition: background-color .1s ease-in
`

export const Question = styled.p`
    line-height: 30px;
    font-size: 16px;
    color: ${p => p.isOpen ? '#db3e00' : '#fff'};
    font-weight: ${p => p.isOpen ? '600' : '100'};
    cursor: pointer;
    margin: 0;
    display: inline-block;

    &:hover {
        color: #db3e00;
        transition: .1s ease-in
    }
`

export const Answer = styled.p`
    line-height: 30px;
    font-size: 16px;
    margin: 0;
    display: block;
    color: #fccbb8
`

export const CollapseIcon = styled.img`
    position: absolute;
    top: 80px;
    right: 20px;
    width: 30px;
    cursor: pointer;
    padding: 4px 14px;
    border-radius: 6px;
    border: 2px solid #fffe;
    background-color: #5fcbee33;
    transition: background-color .1s ease-in;
    z-index: 5;

    &:hover {
        background-color: #5fcbee66;
        transition: .1s ease-in
    }

    // ------ Phone
    @media (max-width: 1024px) {
        display: none
    }
`
