import * as s from './Contact.style';
import { motion } from 'framer-motion';
import { useContext, useEffect } from 'react';
import { Box } from '@material-ui/core';

// Context
import AppContext from 'Context';

const Contact = () => {
  // Context
  const context = useContext(AppContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.nav initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.2 }}>
      <s.Header>
        <span style={{ color: '#ff7a46' }}>M</span>eet the <span style={{ color: '#ff7a46' }}>T</span>eam
      </s.Header>
      <s.IntroMessage>
        GOT QUESTIONS? <br /> Feel free to reach us at{' '}
        <span style={{ color: '#ff7a46', cursor: 'pointer', textDecoration: 'none', fontWeight: context.isMobile ? 900 : 100 }} href="mailto:hello@galtporchfest.caßß">
          hello@galtporchfest.ca
        </span>
        <hr />
        Keep an eye out for these folks on event day. They can help. Except maybe for Penny...
      </s.IntroMessage>
      <s.InfoRowWrapper>
        <s.InfoColumnWrapper>
          <s.PhotoWrapper>
            <s.Photo src="img/priyanka_1.jpg" />
          </s.PhotoWrapper>
          <s.Name>
            <span style={{ color: '#ff7a46' }}>P</span>riyanka - the <span style={{ color: '#ff7a46' }}>M</span>astermind
          </s.Name>
          <s.Description>
            Adventure lover and festival goer, Priyanka has been thinking of organizing a porchfest, after attending Buffalo Porchfest in 2018. After moving to Cambridge in 2021
            during a lockdown she wanted neighborhoods to come alive again and neighbours to bond over their love of music.
          </s.Description>
        </s.InfoColumnWrapper>
        <s.InfoColumnWrapper>
          <s.PhotoWrapper>
            <s.Photo src="img/eugene.jpg" />
          </s.PhotoWrapper>
          <s.Name>
            <span style={{ color: '#ff7a46' }}>E</span>ugene - the <span style={{ color: '#ff7a46' }}>A</span>rtsy <span style={{ color: '#ff7a46' }}>O</span>ne
          </s.Name>
          <s.Description>
            The playful website design was created by Eugene. On event day you can probably find him either connecting cables and moving stuff around or trying to not loose his
            voice singing "Don't Look Back in Anger" on one of the porches. <br />
          </s.Description>
        </s.InfoColumnWrapper>
      </s.InfoRowWrapper>
      <s.InfoRowWrapper>
        <s.InfoColumnWrapper>
          <s.PhotoWrapper>
            <s.Photo src="img/neil.jpg" />
          </s.PhotoWrapper>
          <s.Name>
            <span style={{ color: '#ff7a46' }}>N</span>eil
          </s.Name>
          <s.Description>
            Long time Cambridge resident, owner of{' '}
            <span
              style={{ color: '#ff7a46', cursor: 'pointer', textDecoration: 'none', fontWeight: context.isMobile ? 900 : 100 }}
              href="galtstudentliving.com"
              onClick={() => window.open('https://galtstudentliving.com', '_blank')}
            >
              Galt Student Living
            </span>
            . Big supporter of Galt Community and Downtown Business, porch host for the last two years. Love my porch!
          </s.Description>
        </s.InfoColumnWrapper>
        <s.InfoColumnWrapper>
          <s.PhotoWrapper>
            <s.Photo src="img/penny.jpg" />
          </s.PhotoWrapper>
          <s.Name>
            <span style={{ color: '#ff7a46' }}>P</span>enny
          </s.Name>
          <s.Description>Our friend Penny does not participate in festival organization much but we decided to include her here anyway for funzies.</s.Description>
        </s.InfoColumnWrapper>
      </s.InfoRowWrapper>
      {!context.isMobile && <Box my={6} />}
    </motion.nav>
  );
};

export default Contact;
