import styled from '@emotion/styled';

export const Header = styled.h1`
  font-size: 160%;
  text-align: center;
  letter-spacing: 1px;
  font-family: 'Indie Flower', cursive;
  font-weight: 100;
  margin: 0 0 14px;
`;

export const ArticleWrapper = styled.div`
  display: flex;
  width: 95%;
  background-color: #2b3240;
  justify-content: space-between;
  align-items: center;
  margin: 40px auto 0;
  cursor: pointer;
  border-radius: 15px;
  transition: 0.2s;
  border: 1px solid #576574;
  ${(p) => p.isLastChild && 'margin-bottom: 80px'};

  &:hover {
    background: #384154;
    transition: 0.2s;
    box-shadow: 0 0 10px #ff7a46;
    border: 1px solid transparent;
  }

  // ------ Phone
  @media (max-width: 1024px) {
    width: 100%%;
    flex-direction: column;
    justify-content: center;
    padding: 20px 0;
  }
`;

export const ArticleImageWrapper = styled.div`
  width: 200px;
  height: 84px;
  margin-right: 40px;
  overflow: hidden;

  // ------ Phone
  @media (max-width: 1024px) {
    padding-bottom: 20px;
    height: 64px;
    width: 120px;
    margin-right: 0;
  }
`;

export const ArticleImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 15px 0 0 15px;

  // ------ Phone
  @media (max-width: 1024px) {
    border-radius: 15px;
  }
`;

export const ArticleDescriptionWrapper = styled.div`
  width: 100%;

  // ------ Phone
  @media (max-width: 1024px) {
    padding: 0 20px;
    box-sizing: border-box;
  }
`;

export const Text = styled.h2`
  font-weight: 100;
  font-size: 120%;
  margin: 0;

  // ------ Phone
  @media (max-width: 1024px) {
    font-size: 95%;
  }
`;

export const Date = styled.h3`
  color: #7f8c8d;
  margin: 0;
  fint-weight: 100;
  font-style: italic;
  font-size: 100%;

  // ------ Phone
  @media (max-width: 1024px) {
    font-size: 90%;
    margin-top: 10px;
  }
`;

export const YearLabel = styled.h2`
  margin-bottom: -20px !important;
  color: #bdc3c7;
`;
