import styled from '@emotion/styled';

export const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  background: #2b3240;
  width: auto;
  max-width: 500px; // for "registration closed" message
  // max-width: 450px;
  border-radius: 15px;
  box-shadow: 0 0 10px #ff7a46;
  margin: 0 auto 40px;
  padding: 20px;

  h4 {
    display: inline-block;
    margin: 0;
    font-weight: 100;

    // ------ Phone
    @media (max-width: 1024px) {
      text-align: center;
    }
  }

  a {
    color: #5fcbee;
  }

  // ------ Phone
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const Icon = styled.img`
  height: 30px;
  padding: 0 30px 0 0;

  // ------ Phone
  @media (max-width: 1024px) {
    padding: 0 0 20px 0;
  }
`;
