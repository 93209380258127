import styled from '@emotion/styled';

export const Header = styled.h1`
    font-size: 160%;
    text-align: center;
    letter-spacing: 1px;
    font-family: 'Indie Flower', cursive;
    font-weight: 100;
    margin: 0 0 14px
`

export const IntroMessage = styled.h3`
    text-align: center;
    margin: 18px auto 16px;
    max-width: 80%;
    font-weight: 100;
    font-size: 16px;

    hr {
        margin: 15px;
        border: 0;
        height: 1px;
        background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.75),
            rgba(255, 255, 255, 0)
        );
    }
`

export const InfoRowWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 30px;

    // ------ Phone
    @media (max-width: 1024px) {
        margin-top: 24px;
    }

    // ------ Phone
    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
    }
`

export const InfoColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    width: ${p => p.center ? '45%' : '38%'};
    ${p => p.center && 'margin: 16px auto 50px'};

    // ------ Mobile
    @media (max-width: 1200px) {
        width: ${p => p.center && '60%'};
    }

    // ------ Phone
    @media (max-width: 1024px) {
        width: 100%;
        margin-bottom: ${p => p.center ? 0 : '30px'};
        ${p => p.center && 'margin-top: 0px;'};
    }
`

export const PhotoWrapper = styled.div`
    width: 200px;
    height: 210px;
    margin: 0 auto;
    box-shadow: 0 0 10px #2b3240;
    border: 1px solid #718093;
    box-sizing: border-box;
    border-radius: 30px;
`

export const Photo = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    box-shadow: 0 0 10px #2b3240;
    border-radius: 30px;
`

export const Name = styled.h3`
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 0;
`

export const Description = styled.p`
    line-height: 30px;
    font-size: 16px;
    margin-top: 12px
`
