import * as s from './Porch.style';
import { motion, AnimatePresence } from 'framer-motion';
import { useContext, useRef, useEffect } from 'react';

import CoolButton from 'components/Button/Button';
import RegistrationFormMessage from 'components/RegistrationFormMessage/RegistrationFormMessage';

// Google Map
import { Map, Polygon, GoogleApiWrapper } from 'google-maps-react';
import * as CustomMap from 'Map';

// Material UI
import Switch from '@material-ui/core/Switch';

// Context
import AppContext from 'Context';

const PorchForm = (props) => {
  // Context
  const context = useContext(AppContext);
  const { porchForm, setPorchForm, isMobile, isPhone } = context;

  // Ref
  const mapWrapperRef = useRef();

  const isRegistrationClosed = false;

  // Effect to change factory position property from 'absolute' to 'relative'
  useEffect(() => {
    if (mapWrapperRef.current) {
      mapWrapperRef.current.children[0].style.position = 'relative';

      // Manually set width for mobile
      const wrapperWidth = mapWrapperRef.current.offsetWidth;
      mapWrapperRef.current.children[0].children[0].style.width = wrapperWidth;
    }
  }, []);

  const handleChange = (e, switchElement) => {
    const newPorchForm = { ...porchForm };

    if (e.target.name === 'phone' && (e.target.value.length === 3 || e.target.value.length === 7)) {
      newPorchForm[e.target.name] = e.target.value + '-';
    } else if (switchElement === 'lights') newPorchForm['lights'] = !porchForm.lights;
    else if (switchElement === 'rock-on') newPorchForm['hasOwnAct'] = !porchForm.hasOwnAct;
    else newPorchForm[e.target.name] = e.target.value;

    setPorchForm(newPorchForm);
  };

  const handleKeyDown = (e) => {
    if ((porchForm.phone.length === 5 || porchForm.phone.length === 9) && e.keyCode === 8) {
      e.preventDefault();

      const newPorchForm = { ...porchForm };
      newPorchForm['phone'] = porchForm.phone.substr(0, porchForm.phone.length - 2);
      setPorchForm(newPorchForm);
    }
    if ((porchForm.phone.length === 4 || porchForm.phone.length === 8) && e.keyCode === 8) {
      e.preventDefault();

      const newPorchForm = { ...porchForm };
      newPorchForm['phone'] = porchForm.phone.substr(0, porchForm.phone.length - 2);
      setPorchForm(newPorchForm);
    }
  };

  return (
    <motion.nav initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.2 }}>
      <hr style={{ marginBottom: 0 }} />

      <h3 style={{ textAlign: 'center' }}>Festival boundary:</h3>
      <s.MapWrapper ref={mapWrapperRef}>
        <Map
          google={props.google}
          style={{
            height: '300px',
            width: `${isPhone ? '' : '500px'}`,
            borderRadius: '10px'
          }}
          styles={CustomMap.mapStyle}
          initialCenter={{
            lat: 43.36015742,
            lng: -80.3191788
          }}
          zoom={13.9}
        >
          <Polygon paths={CustomMap.polygonCoords} strokeColor="#ff7a46" strokeOpacity={0.8} strokeWeight={2} fillColor="#ff7a46" fillOpacity={0.35} />
        </Map>
      </s.MapWrapper>

      <hr />

      <form action="https://formsubmit.co/eugene@150ml.com" method="POST" id="porch_form">
        {/* <RegistrationFormMessage messageForType="hosts" messageType="closed" /> */}
        <s.PorchFormWrapper isRegistrationClosed={isRegistrationClosed}>
          <s.PorchFormLeftSide>
            <input
              name="TYPE"
              defaultValue="PORCH HOST"
              style={{ display: 'none' }}
              onChange={() => {
                return;
              }}
            />
            <s.SingleInput>
              <s.Label htmlFor="name">Name</s.Label>
              <s.Input autoComplete="off" id="name" name="name" required value={porchForm.name} onChange={handleChange} />
            </s.SingleInput>

            <s.SingleInput>
              <s.Label htmlFor="address">Porch Address</s.Label>
              <s.Input autoComplete="off" id="address" name="address" placeholder="ex. 1 Water Street" required value={porchForm.address} onChange={handleChange} />
            </s.SingleInput>

            <s.SingleInput>
              <s.Label htmlFor="phone">Phone (optional)</s.Label>
              <s.Input autoComplete="off" id="phone" maxLength={12} name="phone" value={porchForm.phone} onChange={handleChange} onKeyDown={handleKeyDown} />
            </s.SingleInput>

            <s.SingleInput className="last-input">
              <s.Label htmlFor="email">Email</s.Label>
              <s.Input
                autoComplete="off"
                id="email"
                name="email"
                required
                // type='email'
                value={porchForm.email}
                onChange={handleChange}
              />
            </s.SingleInput>

            <input type="hidden" name="_captcha" value="false" />
            <input type="hidden" name="_subject" value="New Porchfest submission!" />
            <input type="text" name="_honey" style={{ display: 'none' }} />
            <input type="hidden" name="_cc" value="priyankakhimasia@gmail.com" />
            <input type="hidden" name="_template" value="table" />
            <input type="hidden" name="_next" value="https://galtporchfest.ca/form-completed" />

            {/* Hack to include MaterialUI switches in FormSubmit */}
            <input
              name="is_electricity_provided"
              value={porchForm.lights ? 'Yes' : 'No'}
              style={{ display: 'none' }}
              onChange={() => {
                return;
              }}
            />
            <input
              name="has_own_act"
              value={porchForm.hasOwnAct ? 'Yes' : 'No'}
              style={{ display: 'none' }}
              onChange={() => {
                return;
              }}
            />

            {!isMobile && (
              <s.ElectricityWrapper checked={porchForm.lights}>
                <s.ElectricityHeader>Does your porch have electricity access?</s.ElectricityHeader>
                <Switch
                  style={{ marginRight: '100px' }}
                  checked={porchForm.lights}
                  color={'primary'}
                  // Change includes fake event
                  onChange={() => handleChange({ target: { name: null } }, 'lights')}
                />
                <s.LightBulb src={`icons/light_${porchForm.lights ? 'on' : 'off'}.svg`} />
              </s.ElectricityWrapper>
            )}
          </s.PorchFormLeftSide>

          <s.PorchFormRightSide>
            {isMobile && (
              <s.ElectricityWrapper checked={porchForm.lights}>
                <s.ElectricityHeader>Does your porch have electricity access?</s.ElectricityHeader>
                <Switch
                  style={{ marginRight: '100px' }}
                  checked={porchForm.lights}
                  color={'primary'}
                  // Change includes fake event
                  onChange={() => handleChange({ target: { name: null } }, 'lights')}
                />
                <s.LightBulb src={`icons/light_${porchForm.lights ? 'on' : 'off'}.svg`} />
              </s.ElectricityWrapper>
            )}

            <s.ExtraActWrapper checked={porchForm.hasOwnAct} hasOwnAct={porchForm.hasOwnAct}>
              <s.ExtraActHeader>Do you also have an act for your porch?</s.ExtraActHeader>
              <Switch
                style={{ marginRight: '100px' }}
                checked={porchForm.hasOwnAct}
                color={'primary'}
                // Change includes fake event
                onChange={() => handleChange({ target: { name: null } }, 'rock-on')}
              />
              <s.RockOn src={`icons/rock-on-${porchForm.hasOwnAct ? 'red' : 'grey'}.png`} />
            </s.ExtraActWrapper>
            <AnimatePresence>
              {porchForm.hasOwnAct && (
                <motion.nav initial={{ opacity: 0, y: -15 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.35 }} exit={!isMobile && { opacity: 0, y: -15 }}>
                  <s.SingleInput rightSide>
                    <s.Label htmlFor="name_of_act" rightSide>
                      Name of Act
                    </s.Label>
                    <s.Input
                      autoComplete="off"
                      id="name_of_act"
                      name="name_of_act"
                      required={porchForm.hasOwnAct}
                      rightSide
                      value={porchForm.name_of_act}
                      onChange={handleChange}
                    />
                  </s.SingleInput>
                  <s.SingleInput rightSide lastChild>
                    {isMobile && <s.Label htmlFor="act_description">Performatnce description&nbsp;</s.Label>}
                    <s.ActDescription
                      id="porch_form"
                      name="act_description"
                      placeholder="Instruments / Genre / Links etc."
                      required={porchForm.hasOwnAct}
                      rows={4}
                      value={porchForm.act_description}
                      onChange={handleChange}
                    />
                  </s.SingleInput>
                </motion.nav>
              )}
            </AnimatePresence>
          </s.PorchFormRightSide>
        </s.PorchFormWrapper>

        {!isRegistrationClosed && (
          <>
            <hr />
            <s.ButtonContainer>
              <CoolButton label={'SUBMIT'} />
            </s.ButtonContainer>
          </>
        )}
      </form>
    </motion.nav>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyARh_P0B3FkRNPP1IXM3Ym2RZiBc_gha_M'
})(PorchForm);
