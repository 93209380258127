import { useEffect, useState } from 'react'

import * as s from './Faq.style'
import { AnimatePresence, motion } from 'framer-motion';

import { Faqs } from 'Faqs'

const Faq = () => {
    const [questionsState, setQuestionsState] = useState([]);
    const [atLeastOneIsOpen, setAtLeastOneIsOpen] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Set each question's isOpen state to false
    useEffect(() => {
        let qState = [];
        Faqs.forEach(() => qState.push(false));
        setQuestionsState(qState)
    }, [])

    const handleQuestionClick = questionIdx => {
        const questionsStateCopy = [...questionsState];
        questionsStateCopy[questionIdx] = !questionsState[questionIdx];

        // Check if at least one question is open
        let open = questionsStateCopy.every(question => question === false);
        
        setQuestionsState(questionsStateCopy)
        setAtLeastOneIsOpen(!open)
    }

    const handleCollapseClick = () => {
        const newQuestionsState = [];
        for(let i = 0; i <= questionsState.length; i++) {
            newQuestionsState.push(false)
        }
        setQuestionsState(newQuestionsState)
        setAtLeastOneIsOpen(false)
    }

    const questionsJSX = Faqs.map((item, mainIndex) => {
        const isAnswerOpen = questionsState[mainIndex];
        const answer = () => {
            if(Array.isArray(item.answer)) {
                const answersList = item.answer.map((secondaryItem, answerIndex) => <s.Answer key={answerIndex} isOpen={questionsState[mainIndex]}>{secondaryItem}</s.Answer>);
                return <>{answersList}</>
            }
            return <s.Answer isOpen={questionsState[mainIndex]}>{item.answer}</s.Answer>
        }
        return (
            <s.QuestionWrapper key={mainIndex} isOpen={questionsState[mainIndex]} onClick={() => questionsState[mainIndex] ? handleQuestionClick(mainIndex) : null}>
                <s.Question onClick={() => handleQuestionClick(mainIndex)} isOpen={questionsState[mainIndex]}>{item.question}</s.Question>
                <AnimatePresence>
                    { isAnswerOpen && (
                        <motion.nav 
                            initial={{ opacity: 0, y: -15 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.35 }}
                            exit={{ opacity: 0, x: -30 }}
                        >
                            {  answer() }
                        </motion.nav>
                    )}
                </AnimatePresence>
            </s.QuestionWrapper>
        )
    })

    return (
        <motion.nav
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.2 }}
        >
            <s.FaqContainer>
                <s.Header><span style={{ color: '#ff7a46'}}>F</span>requently <span style={{ color: '#ff7a46'}}>A</span>sked <span style={{ color: '#ff7a46'}}>Q</span>uestions</s.Header>
                { questionsJSX }

                <AnimatePresence>
                    { atLeastOneIsOpen && (
                        <motion.nav 
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.35 }}
                            exit={{ opacity: 0 }}
                        >
                            <s.CollapseIcon src='icons/collapse.svg' onClick={handleCollapseClick} />
                        </motion.nav>
                    )}
                </AnimatePresence>
            </s.FaqContainer>
        </motion.nav>
    ) 
}

export default Faq