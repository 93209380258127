// isNew: bool ---> if true, it means they don't have photos for the gallery. This prop is used on gallery page
// yearsPerformed ---> not used anywhere now but potentially to be used for future festivals
export const currentPerformersList = {
  col1: [
    {
      name: 'Acoustic JAK',
      bio: 'Acoustic JAK came together in late 2022 and had their first performance in May 2023. A husband and wife combo with Kyle come together to play some of their favorite some of their favorite popular songs in a soulful and unique acoustic sound. ',
      photo: 'img/artists/acoustic_jak.jpg',
      genre: 'Soulful and unique acoustic',
      isNew: true,
      yearsPerformed: [],
      links: [
        {
          url: 'https://www.instagram.com/acousticjakband/',
          icon: 'icons/social/ig.svg'
        }
      ]
    },
    {
      name: 'Beige Shelter',
      bio: 'Beige Shelter is an ambitious and eclectic five-piece band from Mississauga, Ontario that aims to bring together hearts, souls, fire and passion through the only way we know how... our love for music!<br /><br />Music has been our beloved way of expressing feelings, thoughts... just like shelter to a newborn baby. Beige includes all kinds of colours or emotions (red for anger, blue for sadness, green for envy, yellow for joy).<br /><br />Whether through an angry rocker or a sad ballad, improving mental health, self-acceptance, resilience, love, exploring emotions and fighting for human rights are the main pillars of our message to our fans.',
      photo: 'img/artists/beige_shelter.jpg',
      genre: 'Tom Petty meets Sam Roberts',
      isNew: true,
      yearsPerformed: [],
      links: [
        {
          url: 'https://www.sonicbids.com/band/beige-shelter/',
          icon: 'icons/social/site.svg'
        },
        {
          url: 'https://www.facebook.com/beigeshelter',
          icon: 'icons/social/fb.svg'
        },
        {
          url: 'https://www.instagram.com/beigeshelter/',
          icon: 'icons/social/ig.svg'
        }
      ]
    },
    {
      name: 'Between Names',
      bio: 'Kitchener-Waterloo trio featuring Christen Latham (Vocals), Simon Lewis (Vocals/Guitar) and John Stuart (Guitar/Vocals).<br /><br />3 local artists come together in an acoustic melodic harmony. Latham and Lewis, formerly Zook & Boots, in summer of 2023 added guitarist Stuart too make it a trio. Each have played for many years and are well known for what they do. Between Porchfests, special events, and pub gigs, "Between Names" delivers modern and classic pop/R&B music as well as their own original work relative to their roots. Giving a modern tone like in “Circles” track written by Zook & Boots back in 2010, re-recorded in 2023..<br /><br />The trio is great for all kinds of events.',
      photo: 'img/artists/zook.jpeg',
      genre: 'Acoustic classic pop / R&B, originals',
      isNew: true,
      yearsPerformed: [],
      links: [
        {
          url: 'https://www.youtube.com/watch?v=zgYD6c0HVro',
          icon: 'icons/social/youtube.svg'
        },
        {
          url: 'https://www.instagram.com/p/Ch0zlXKA27n',
          icon: 'icons/social/ig.svg'
        }
      ]
    },
    {
      name: 'Cambridge Symphony Orchestra',
      bio: "The Cambridge Symphony Orchestra will be celebrating its 20th anniversary this year! It started as an idea with a few players performing at the Mayor's Celebration for the Arts and it has grown from there to the great orchestra we enjoy today. From small classic performances, to the Nutcracker ballet performance during the holidays, opera singers and great solo performers, the CSO provides orchestral music for everyone.<br /><br />Expect to see a beautiful repertoire of orchestra music from their portfolio.",
      photo: 'img/artists/cso.jpg',
      genre: 'Orchestral',
      isNew: true,
      yearsPerformed: [],
      links: [
        {
          url: 'https://www.cambridgeorchestra.ca/',
          icon: 'icons/social/site.svg'
        },
        {
          url: 'https://www.facebook.com/cambridgeorchestra/',
          icon: 'icons/social/fb.svg'
        }
      ]
    },
    {
      name: 'Charlie Mills',
      bio: 'Charlie plays folk rock covers - Dylan, The Band, Neil Young, Morrison etc. He released 3 CDs. Charlie writes his own songs and has played many venues.',
      photo: 'img/artists/charlie_mills.jpg',
      isNew: false,
      yearsPerformed: [2023],
      links: [
        {
          url: 'https://open.spotify.com/artist/4RrDchIgXX2UzrQqnfjeuv?si=cmeAdf9rTGGGebprSNn1wQ',
          icon: 'icons/social/spotify.png'
        },
        {
          url: 'https://soundcloud.com/charliemillsmusic',
          icon: 'icons/social/soundcloud.png'
        },
        {
          url: 'https://www.youtube.com/@charliemills5889',
          icon: 'icons/social/youtube.svg'
        }
      ]
    },
    {
      name: 'Craig McNair',
      bio: 'Craig McNair is a Canadian singer-songwriter and has been performing in the Kitchener-Waterloo area for over 15 years. In addition to his solo act, he has also performed with several bands including Carry On: The Music of CSNY, Lofi Mind, Turk & The Hobbits, Escape to Laurel Canyon, and in many duos.',
      photo: 'img/artists/craig_mcnair.png',
      genre: 'Singer / songwriter',
      isNew: false,
      yearsPerformed: [2023],
      links: [
        {
          url: 'https://craigmcnair.com',
          icon: 'icons/social/site.svg'
        },
        {
          url: 'https://www.youtube.com/@craig.mcnair',
          icon: 'icons/social/youtube.svg'
        },
        {
          url: 'https://www.instagram.com/craigmcnairmusic/',
          icon: 'icons/social/ig.svg'
        },
        {
          url: 'https://www.facebook.com/craig.mcnair.714',
          icon: 'icons/social/fb.svg'
        }
      ]
    },
    {
      name: 'The Craig Robertson Band',
      bio: "This will be the 4th year the Craig Robertson Band have played Porchfest and they are happy to announce a drummer is in the fold. With Jeff Dunk handling bass, Jeff Wilson joins to complete the trio playing songs from Craig's upcoming album 'Running the Other Way', due this fall. They will also be playing songs from Craig's previous releases, 'Family Name' and 'Late Mornings' (produced by Michael Timmins of the Cowboy Junkies), along with some choice cover songs.",
      photo: 'img/artists/craig_robertson.jpg',
      genre: 'Folk / rock',
      isNew: true,
      yearsPerformed: [],
      links: [
        {
          url: 'https://craigrobertsonmusic.com/',
          icon: 'icons/social/site.svg'
        },
        {
          url: 'https://www.facebook.com/Craig-Robertson-Music-197587927020635/',
          icon: 'icons/social/fb.svg'
        },
        {
          url: 'https://www.youtube.com/watch?v=9oB89nHpLSY',
          icon: 'icons/social/youtube.svg'
        },
        {
          url: 'https://www.instagram.com/craigrobertsonmusic/',
          icon: 'icons/social/ig.svg'
        }
      ]
    },
    {
      name: 'Dan Badger',
      bio: "In 2018 Cambridge, Ontario based Artist Dan Badger stepped into the studio (Cedartree Studios) to record a string of self-written singles including his debut single 'I Want to Kiss You' and he hasn't looked back since. Over his years of performing Dan has had the opportunity to play with some of the best in the industry. He has had the pleasure of opening for; Terry Sumsion, George Fox, George Canyon, Julian Austin, Blake Shelton and more. He has also opened up for such rock artists as Glass Tiger and Brett Michaels of Poison. In addition to playing venues all over Southwestern Ontario, Dan has played at many festivals and fairs, including the Fergus Truck Show, the Kitchener Truckfest and the Burlington Sound Of Music Festival.",
      photo: 'img/artists/dan_badger.jpeg',
      genre: 'Country acoustic',
      isNew: false,
      yearsPerformed: [2021, 2022, 2023],
      links: [
        {
          url: 'https://dan-badger.com/',
          icon: 'icons/social/site.svg'
        },
        {
          url: 'https://open.spotify.com/artist/0JgrAu50yI0nIcD3077EUG',
          icon: 'icons/social/spotify.png'
        },
        {
          url: 'https://www.instagram.com/thedanbadger/',
          icon: 'icons/social/ig.svg'
        }
      ]
    }
  ],
  col2: [
    {
      name: 'Ekogemah',
      bio: 'A group of musicians performing traditional music from the South American Andes with a wide range of instruments including wind (pan flute), strings and percussions.',
      photo: 'img/artists/ekogemah.jpg',
      genre: 'Andean South American traditional',
      isNew: false,
      yearsPerformed: [2022, 2023],
      links: []
    },
    {
      name: 'Father and Son Reunion',
      bio: "Rick and Alex Dlugokecki, both born and raised in Kitchener, have been performing live music in a wide variety of groups/styles for collectively over 85 years. Rick is a talented guitarist, and Alex is a professionally trained drummer/percussionist who also plays piano and guitar.<br /><br />We'll be playing a range of fun covers from the 1950s to the present.",
      photo: 'img/artists/father_and_son.jpg',
      genre: 'Rock, Pop, Country, Folk, Jazz',
      isNew: false,
      yearsPerformed: [2023],
      links: [
        {
          url: 'https://www.youtube.com/watch?v=xV5QS602NsY',
          icon: 'icons/social/youtube.svg'
        }
      ]
    },
    {
      name: 'Gregory Boivin',
      bio: 'Gregory Boivin was born and raised in Brockville, Ontario.  He currently resides in Toronto, where he has recruited a wonderful list of talented musicians (e.g., Grammy & Polaris Prize nominated) for his folk debut, "A Blanket of Years".  For Porchfest, Gregory will be singing original indie folk songs solo with his acoustic guitar.',
      photo: 'img/artists/gregory_boivin.jpg',
      genre: 'Singer-songwriter, folk, indie folk',
      isNew: true,
      yearsPerformed: [],
      links: [
        {
          url: 'http://www.instagram.com/greg_boivin',
          icon: 'icons/social/ig.svg'
        }
      ]
    },
    {
      name: 'The Harmony Riders',
      bio: "The Harmony Riders are made up of Gillian Dyck and Katy Topham, both locals from the tri-cities; Cambridge and Kitchener respectively. Katy Topham is a Kitchener based singer-songwriter, who most recently released her sophomore EP 'The Hurtin' and the Heart' in 2022. Since 2019 Katy has released 8 singles, all resulting in radio play and available online. In addition to performing locally, Katy has performed at several festivals and local events, including performing at the CCMAs in both 2012 and 2021.<br /><br />Gillian is a Nurse, a Life & Wellness Coach and a Mother returning to her passion for music. Gillian is trained in Classical Music and 'back in the day' explored Jazz, Chamber and Choral music as well. Katy and Gillian met at EBolt Music in Cambridge when Gillian's daughter came for lessons. They soon discovered their sibling-like harmonic qualities when they sang together! Life sure has ways of bringing the people you need into your circle! Thus the Harmony Riders were born!",
      photo: 'img/artists/gillian_dyck.jpeg',
      genre: 'Soulful and unique acoustic',
      isNew: true,
      yearsPerformed: [],
      links: []
    },
    {
      name: 'Kyle Moning',
      bio: "Kyle is a local singer-songwriter who writes captivating original songs that will have you tapping your feet and humming along. His storytelling lyrics are engaging, genuine and relatable. <br /><br />When he's not playing on a porch, you can find Kyle playing at your favourite local breweries, bars, and festivals.",
      photo: 'img/artists/kyle_moning.jpg',
      genre: 'Singer-Songwriter, Pop, Rock, Folk',
      isNew: false,
      yearsPerformed: [2021, 2022, 2023],
      links: [
        {
          url: 'https://www.facebook.com/KyleMoningMusic/',
          icon: 'icons/social/fb.svg'
        },
        {
          url: 'https://soundcloud.com/kyle-moning',
          icon: 'icons/social/soundcloud.png'
        }
      ]
    },
    {
      name: 'Liam Hoff and the Heavy Hitters',
      bio: '13 year old local Musician Liam Hoff and his band play everything from reggae to Blues to oldies to Modern Hits. Liam has been seen playing the hot springs Festival in Preston, the Kitchener Waterloo Multicultural Fest.',
      photo: 'img/artists/liam_hoff.jpg',
      genre: 'Covers and originals',
      isNew: false,
      yearsPerformed: [2023],
      links: [
        {
          url: 'https://www.youtube.com/watch?v=UPuR20JJIv8',
          icon: 'icons/social/youtube.svg'
        }
      ]
    },
    {
      name: 'Naked Bear DJ',
      bio: 'DJ/Producer/Loop Artist from Toronto, Canada. Original and The best in nu disco, deep house, electro funk and all things that make those feet sweat!',
      photo: 'img/artists/nakedbear.jpg',
      genre: 'New Disco, Swing House, Electro Funk',
      isNew: false,
      yearsPerformed: [2023],
      links: [
        {
          url: 'https://www.instagram.com/nakedbeardj',
          icon: 'icons/social/ig.svg'
        }
      ]
    }
  ],
  col3: [
    {
      name: 'Onion Honey',
      bio: "Onion Honey is a local 4 or 5-piece old-time folk band: banjo, guitar, fiddle, mandolin, lots of harmonies. We play a mixture of original and traditional americana songs, as well as lots of folkified covers and singalongs. It's toe-tapping and heart-warming!",
      photo: 'img/artists/onion_honey.jpg',
      genre: 'Americana / folk',
      isNew: false,
      yearsPerformed: [2021, 2022, 2023],
      links: [
        {
          url: 'https://onionhoney.com',
          icon: 'icons/social/site.svg'
        },
        {
          url: 'https://www.facebook.com/onionhoneymusic',
          icon: 'icons/social/fb.svg'
        },
        {
          url: 'https://www.instagram.com/onionhoneymusic/',
          icon: 'icons/social/ig.svg'
        },
        {
          url: 'https://www.youtube.com/user/onionhoneymusic',
          icon: 'icons/social/youtube.svg'
        },
        {
          url: 'https://open.spotify.com/artist/71IGofHSddxgaWQLcnGC5X',
          icon: 'icons/social/spotify.png'
        }
      ]
    },
    {
      name: 'Silvia Dee & the Boyfriend',
      bio: "Based in Waterloo Region, they’ve appeared numerous festivals across southern Ontario & local porch party festivals. Together they perform familiar tunes with a twist, writing and recording original music with their larger project, 'Waterstreet' founded in Cambridge, Ontario.</br /></br />Silvia Dee & the Boyfriend were selected to perform as Via Rail “artists on board” for the eastern route to Halifax and back. Silvia's new song 'Riverside Child' was a finalist in the 2020 International Blues & Roots Songwriting competition and represented the Grand River Blues Association at the International Blues Challenge in Memphis 2017.",
      photo: 'img/artists/silvia_dee.jpg',
      genre: 'Rootsy blues with a side order of Louisiana spice',
      isNew: true,
      yearsPerformed: [],
      links: [
        {
          url: 'http://silviadeeandtheboyfriends.com',
          icon: 'icons/social/site.svg'
        },
        {
          url: 'https://www.facebook.com/silviadeeandtheboyfriends',
          icon: 'icons/social/fb.svg'
        },
        {
          url: ' https://www.instagram.com/silviadeeandtheboyfriends',
          icon: 'icons/social/ig.svg'
        },
        {
          url: 'https://www.youtube.com/waterstreetband ',
          icon: 'icons/social/youtube.svg'
        }
      ]
    },
    {
      name: 'Tea for Three',
      bio: "Tea For Three consists of Paul Morris, Gwen Potter & Brad McEwen, who are also members of the band Tethera. The repertoire is traditional folk music, mostly from England, but with lots of tunes from France, Belgium, Scotland, Canada, Scandinavia & anywhere else as long as they like it & it fits. <br /><br />Paul plays a melodeon (button accordion)<br />Gwen plays a viola d'amore (5 string violin with sympathetic strings)<br />Brad plays a cittern (mandolin on steroids), with Tethera & other configurations.<br /><br />They have performed at folk festivals & events all across Ontario and in the UK.",
      photo: 'img/artists/t43.jpeg',
      genre: 'Traditional folk music',
      isNew: false,
      yearsPerformed: [2022, 2023],
      links: [
        {
          url: 'https://t43folk.weebly.com/events.html',
          icon: 'icons/social/site.svg'
        }
      ]
    },
    {
      name: 'Tyler Montague March',
      bio: 'Cambridge based musician playing classic and current covers.',
      photo: 'img/artists/tyler_montague_march.png',
      genre: 'Acoustic guitar, pop, folk',
      isNew: false,
      yearsPerformed: [2023],
      links: [
        {
          url: 'https://instagram.com/tylermarchmusic',
          icon: 'icons/social/ig.svg'
        }
      ]
    },
    {
      name: 'Terns',
      genre: 'Indie Folk Rock',
      photo: 'img/artists/terns.jpeg',
      bio: 'Holding to no real genre markings, blending jazzy, jammy and rock inspired grooves with fuzzed out guitar lines, ukulele, banjo and basically anything else that happens to be lying around during the recording process, Terns write songs about life, love, kids and the great divide.',
      isNew: false,
      yearsPerformed: [2021, 2022, 2023],
      links: [
        {
          url: 'https://open.spotify.com/artist/1iuyUJG8E0u8CBRScoMC7o',
          icon: 'icons/social/spotify.png'
        }
      ]
    },
    {
      name: 'The Rewind Line',
      bio: "We play covers primarily in the era of late 90's/2000's alt rock, with some older classics and new fun songs thrown in.",
      photo: 'img/artists/the_rewind_line.jpg',
      genre: '2 guitar/vocalists, keys, bass',
      isNew: false,
      yearsPerformed: [2023],
      links: [
        {
          url: 'https://facebook.com/therewindline',
          icon: 'icons/social/fb.svg'
        },
        {
          url: 'https://youtube.com/@therewindline4119',
          icon: 'icons/social/youtube.svg'
        }
      ]
    },
    {
      name: 'Zenen',
      bio: 'Zenen Treadwell is a self-proclaimed "musician", pro-bono barista, full-time volunteer, and general builder of digital things. His acoustic guitar-driven pop music reflects a diverse range of influences from LCD Soundsystem to Barenaked Ladies.<br /><br />Zenen performs covers of indie classics and whatever was playing on 96.7 CHYM FM in the early 2000s, along with a handful of original songs inspired by his experience of growing up in the Age of Information.',
      photo: 'img/artists/zenen.jpg',
      genre: 'Acoustic, indie, pop',
      isNew: true,
      yearsPerformed: [],
      links: [
        {
          url: 'https://zenen.space',
          icon: 'icons/social/site.svg'
        }
      ]
    }
  ]
};

export const allPerformersFromAllTime = {
  col1: [
    {
      name: 'Aldara',
      bio: 'Guitar and vocals cover duo consisting of Alden and Mara.',
      photo: 'img/artists/temp.png',
      genre: 'Acoustic guitar / flute',
      isNew: false,
      yearsPerformed: [2021],
      links: []
    },
    {
      name: 'Brian Gardiner',
      bio: "Since the 1980's Brian Gardiner's musical experience has seen him playing in bands. Prior to the Covid lockdowns he began working on a solo show and spent the time of Covid building a repertoire to be ready to go once things opened up.",
      photo: 'img/artists/brian_gardiner.jpg',
      genre: 'Rock / blues / folk / country',
      isNew: false,
      yearsPerformed: [2021],
      links: [
        {
          url: 'http://www.briangardiner.ca/',
          icon: 'icons/social/site.svg'
        },
        {
          url: 'https://www.facebook.com/groups/112066590239878',
          icon: 'icons/social/fb.svg'
        },
        {
          url: 'https://www.instagram.com/briangardinermusic/',
          icon: 'icons/social/ig.svg'
        }
      ]
    },
    {
      name: 'Ceadda Casarin',
      bio: 'Ceadda Casarin - percussionist using wooden frogs and tongue drums. Originally a drummer for many years in many bands ranging from folk to punk metal. Toured canada in 2002. Gave up the drum kit for more percussive sounds that resonate with the soul and nature. inspiring musical performances that paint the feel of the atmosphere.',
      photo: 'img/artists/ceadda.jpg',
      genre: 'Percussionist using wooden frogs and tongue drums',
      isNew: false,
      yearsPerformed: [2022],
      links: [
        {
          url: 'https://www.facebook.com/ridelkybimkit',
          icon: 'icons/social/fb.svg'
        },
        {
          url: 'https://www.instagram.com/p/ChNTQ1xguRX/',
          icon: 'icons/social/ig.svg'
        }
      ]
    },
    {
      name: 'Chandler McQuade with Kat',
      bio: "Acoustic trio with guitar, fiddle and rotating with banjo or mandolin. You can find them performing regularly at Rhythm & Brews in Cambridge or stop by to see them play at Kitchener's Neighbors day on June 18 2022.",
      photo: 'img/artists/chandler_with_kat.jpg',
      genre: 'Acoustic trio',
      isNew: false,
      yearsPerformed: [2022],
      links: [
        {
          url: 'https://chandlermcquade.com',
          icon: 'icons/social/site.svg'
        },
        {
          url: 'https://facebook.com/chandlermcquade',
          icon: 'icons/social/fb.svg'
        },
        {
          url: 'https://instagram.com/chandleRmcquadewitHkat',
          icon: 'icons/social/ig.svg'
        }
      ]
    },
    {
      name: 'Connor Roy',
      bio: 'I am a local singer/songwriter. I play the guitar, and perform mostly original material.',
      photo: 'img/artists/connor_roy.jpg',
      genre: 'Singer / songwriter',
      isNew: false,
      yearsPerformed: [2023],
      links: [
        {
          url: 'https://www.tiktok.com/@connorroy37',
          icon: 'icons/social/site.svg'
        },
        {
          url: 'https://instagram.com/connorroy37',
          icon: 'icons/social/ig.svg'
        }
      ]
    }
  ],
  col2: [
    {
      name: 'Eugene Costov',
      bio: "Growing up in Moldova, there was a thriving underground culture for American pop and rock music. I taught myself to play guitar when I was a teenager with the aim to become a rock star. Although I never made a career out of music, I did gigs in Moscow with a band and since coming to Canada I enjoy going to open mic nights. I currently work as software engineer and I am teaching myself piano. I look forward to performing at Galt's first porchfest!",
      photo: 'img/artists/ugene.jpg',
      genre: 'Funk / rock',
      isNew: false,
      yearsPerformed: [2021],
      links: [
        {
          url: 'https://soundcloud.com/eugene-costov',
          icon: 'icons/social/soundcloud.png'
        },
        {
          url: 'https://www.instagram.com/eugene_costov/',
          icon: 'icons/social/ig.svg'
        },
        {
          url: 'https://www.youtube.com/watch?v=8hY26BV9aY8',
          icon: 'icons/social/youtube.svg'
        }
      ]
    },
    {
      name: 'Jay and Scotty',
      genre: 'Rock / some Folk',
      photo: 'img/artists/two_by_six.jpg',
      bio: 'Two guitars playing classic rock, oldies, and jazz.',
      isNew: true,
      yearsPerformed: [2023],
      links: [
        {
          url: 'https://www.twobysixstring.com',
          icon: 'icons/social/site.svg'
        },
        {
          url: 'https://www.facebook.com/twobysix',
          icon: 'icons/social/fb.svg'
        },
        {
          url: 'https://www.instagram.com/twobysixstring/',
          icon: 'icons/social/ig.svg'
        },
        {
          url: 'https://www.youtube.com/watch?v=RV_4Aw681qI',
          icon: 'icons/social/youtube.svg'
        }
      ]
    },
    {
      name: 'Josh Cairney',
      bio: "Josh Cairney wears many outfits within a commonly themed sonic range. Let's listen to some songs that are under 3 minutes, together.",
      photo: 'img/artists/josh_cairney.jpeg',
      genre: 'Folk & Rock',
      isNew: false,
      yearsPerformed: [2022],
      links: []
    },
    {
      name: 'Kate Ruckus',
      bio: 'A blend of ska, punk, and whatever else. Part of the Cambridge trio The Axxxidentals.',
      photo: 'img/artists/kate_ruckus.jpg',
      genre: 'Guitar, vocals, solo musician.',
      isNew: false,
      yearsPerformed: [2023],
      links: [
        {
          url: 'https://www.instagram.com/kateruckus',
          icon: 'icons/social/ig.svg'
        }
      ]
    },
    {
      name: "McLovin' & Sarah McGrath",
      bio: 'Two acoustic/electric Guitars / 5 singers / Electric Bass / cajon / L1 Bose System',
      photo: 'img/artists/temp.png',
      genre: 'Classic Rock / Alternative Covers',
      isNew: false,
      yearsPerformed: [2022],
      links: []
    },
    {
      name: 'Neil Light',
      bio: 'Neil Light is a seasoned musician with experience in a wide variety of musical genres from rock to bluegrass, swing, gospel and folk. He got his first acoustic guitar when he was 13 and his first electric bass, a gold fleck Winston when he was 14. He performed as a bassist with Gaslight in the sixties, with Axe Records recording artists Starchild during the seventies, Downwind and Thief in the Night in the eighties and The Grand River Orchestra and Wake the Giants in later years.<br /><br />Neil walked the Camino de Santiago in 2010. The 32-day walk along the ancient pilgrimage road in Spain led to the 2016 release of his cd “The Road” a compilation of music inspired by the adventure.<br /><br />        In more recent years Neil has turned his attention to guitar and has been performing musical storytelling pieces “The Invisible Cowboy” and “The Light”, mostly for church-oriented events.<br /><br />Neil also performs as a solo guitarist/singer/songwriter with a large repertoire of original songs and a collection of songs by Canadian and American folk singers and songwriters.<br /><br /> Neil lives with his lifelong friend and treasured wife Janet and Reuben the cat.',
      photo: 'img/artists/neil_light.jpg',
      genre: 'Singer-Songwriter',
      isNew: false,
      yearsPerformed: [2021],
      links: [
        {
          url: 'https://www.youtube.com/watch?v=mxED1G4SN40',
          icon: 'icons/social/youtube.svg'
        }
      ]
    }
  ],
  col3: [
    {
      name: 'Pay No Mind',
      bio: 'Pay No Mind is a tri-city post-punk band from Canada, formed in 2009. Some of their influences include Joy Division, Nick Cave, Johnny Cash, Bauhaus, Peter Murphy, The Doors, Mark Lanegan, The Misfits, The Ramones, and The Velvet Underground. Described as “not your usual band”, you will want to pay attention to Pay No Mind.',
      photo: 'img/artists/pay_no_mind.jpg',
      genre: 'Covers and originals',
      isNew: false,
      yearsPerformed: [2023],
      links: [
        {
          url: 'https://paynomindband.wordpress.com',
          icon: 'icons/social/site.svg'
        }
      ]
    },
    {
      name: 'Sussy Baka',
      bio: "- two parts rhythm section who's chemistry has been forged in the cauldron of the Toronto indie scene over the past 20 plus years<br /><br />- one part local guitar maestro<br /><br />- one part violinist/vocalist extraordinaire.<br /><br />Consisting of David Capannelli on drums/percussion, Robert Lambke on bass (both formerly of Toronto indie dream pop band blueScreen), Philip Main on guitar (the genius behind more punk and ska bands than you can shake a stick at) and newly transplanted, super-talented violinist/vocalist Emily Misura, each bringing their own talents and years of experience to this exciting new project.<br /><br />Born from the ashes of many years, shows and collectives, Sussy Baka strives to bring you renditions of a few of our favourite indie and post-punk tracks. We look forward to having fun with y'all!",
      photo: 'img/artists/sassy_baka.jpg',
      genre: 'Indie/Post-Punk covers with a twist!',
      yearsPerformed: [2021],
      links: []
    },
    {
      name: 'The Boys & I',
      genre: 'Classic rock, country, alternative',
      photo: 'img/artists/boys_and_i.jpeg',
      bio: "Together, we are 'The Boys & I'. Formed in 2018 in Kitchener On, Canada, The Boys & I draw inspiration from pop, heavy metal, rock, and country to land on a sound like is uniquely our own.",
      isNew: false,
      yearsPerformed: [2022],
      links: [
        {
          url: 'https://theboysandiband.com/',
          icon: 'icons/social/site.svg'
        },
        {
          url: 'https://facebook.com/theboysandiband',
          icon: 'icons/social/fb.svg'
        },
        {
          url: 'https://www.instagram.com/theboysandiband/',
          icon: 'icons/social/ig.svg'
        }
      ]
    },
    {
      name: 'Two by Six',
      genre: 'Rock / some Folk',
      photo: 'img/artists/two_by_six.jpg',
      bio: 'Two by Six started as two 6-string guitar players playing your favourite tunes that has grown into "the Twobysix band". Jason Walsh and Terry Gaudet joined together early 2017 to create a guitar duo but quickly grown into "the Two by Six band". Scotty Urquhart, one of the original owners of "Pop the Gator" in downtown Kitchener in the 90s, joined shortly after on the rythm guitar. And amazing drummer Edwin "Fingers!" completed the quartet.<br /><br />Their music includes mostly rock and some mild country. Expect to hear some songs from Tom Petty, The hip, Steve Earle and many more! They also plan on mixing in some of our own material along the way :) <br /><br />Joining Two by Six is a multi-instrumental player and songbird, Rosalyn Urquhart. She debuted at The Boathouse in Kitchener at 11 years of age. She has performed at The Starlight and Ethel’sand in Waterloo, Monograms and Café 13 in Cambridge. She was fortunate to have studied piano with Eric Bolt. Rosalyn sang the Canadian anthem for the Winter Hawks for many years. She is looking forward to performing in her hood with her dad at Porchfest!',
      isNew: false,
      yearsPerformed: [2021],
      links: [
        {
          url: 'https://www.twobysixstring.com',
          icon: 'icons/social/site.svg'
        },
        {
          url: 'https://www.facebook.com/twobysix',
          icon: 'icons/social/fb.svg'
        },
        {
          url: 'https://www.instagram.com/twobysixstring/',
          icon: 'icons/social/ig.svg'
        },
        {
          url: 'https://www.youtube.com/watch?v=RV_4Aw681qI',
          icon: 'icons/social/youtube.svg'
        }
      ]
    },
    {
      name: 'Wendy Bouie & David Flowers',
      bio: "Wendy Bouie, born in Ontario, began singing and song writing at age twelve. Writing from her own life experiences, her music and lyrics express her views on love, life, and the world we live in.<br /><br />Wendy's journey began in the Montreal Music Scene as an Independent Artist, establishing herself as a singer/songwriter, writing and recording her 1st CD 'Mask of Virtue'.  She joined The Songwriters Association of Canada and and became a member of SOCAN as both Artist and Publisher. Returning to Ontario in 2018 with bassist David Flowers, they are focusing on video release of her original music, recording and performing.",
      photo: 'img/artists/wendy_bouie.jpg',
      genre: 'A mix of originals and classic covers',
      isNew: false,
      yearsPerformed: [2021],
      links: [
        {
          url: 'https://wendybouiemusic.com',
          icon: 'icons/social/site.svg'
        },
        {
          url: 'https://www.youtube.com/watch?v=mD5pbJb9GtE',
          icon: 'icons/social/youtube.svg'
        }
      ]
    },
    {
      name: 'Zook & Legkicks',
      bio: 'Christen "Zook" Latham and Johnny "Legkicks" Stuart both native to the Cambridge area have come together to put their own twist on covers and originals.',
      photo: 'img/artists/zook.jpeg',
      genre: 'Acoustic originals and covers',
      isNew: false,
      yearsPerformed: [2021],
      links: [
        {
          url: 'https://youtu.be/7Hr4WSGTQAQ',
          icon: 'icons/social/youtube.svg'
        }
      ]
    }
  ]
};

const getPastArtistsForGallery = () => {
  const artistsArr = [];
  for (let column in allPerformersFromAllTime) {
    allPerformersFromAllTime[column].map((artist) => artistsArr.push(artist));
  }
  return artistsArr;
};

export const pastPerformersListForGallery = getPastArtistsForGallery();

export const schedule = [
  {
    name: 'Silvia Dee & the Boyfriend',
    genre: 'Rootsy blues with a side order of Louisiana spice',
    time: '12:00 - 1:00pm',
    porch: '31 Brant Road North'
  },
  {
    name: 'Liam Hoff and the Heavy Hitters',
    genre: '13 year old local drummer and his band',
    time: '1:00 - 2:00pm',
    porch: 'Ferguson Cottage / 37 Grand Avenue South'
  },
  {
    name: 'Father and Son Reunion',
    genre: 'Rock, Pop, Country, Folk, Jazz',
    time: '1:00 - 2:00pm',
    porch: '30 Brant Rd North'
  },
  {
    name: 'Onion Honey',
    genre: 'Americana / folk / bluegrass',
    time: '2:00 - 3:00pm',
    porch: '33 Brant Road North'
  },
  {
    name: 'Tea for Three',
    genre: 'Traditional folk music - accordion, viola and cittern',
    time: '2:30 - 3:30pm',
    porch: 'Ferguson Cottage / 37 Grand Avenue South'
  },
  {
    name: 'Terns',
    genre: 'Indie Folk Rock',
    time: '2:30 - 3:30pm',
    porch: '36 Grant Street'
  },
  {
    name: 'The Craig Robertson Band',
    genre: 'Folk / rock',
    time: '3:00 - 4:00pm',
    porch: '32 Brant Road North'
  },
  {
    name: 'The Rewind Line',
    genre: '2 guitar/vocalists, keys, bass',
    time: '3:00 - 4:00pm',
    porch: '40 Hillcrest Drive'
  },
  {
    name: 'Tyler Montague March',
    genre: 'Acoustic guitar, pop, folk',
    time: '3:00 - 4:00pm',
    porch: '11 Brant Pl'
  },
  {
    name: 'Ekogemah',
    genre: 'Andean South American traditional',
    time: '3:00 - 4:00pm',
    porch: '91 Blair Road'
  },
  {
    name: 'Beige Shelter',
    genre: 'Tom Petty meets Sam Roberts',
    time: '3:30 - 4:30',
    porch: '131 Blair Road'
  },
  {
    name: 'Kyle Moning',
    genre: 'Singer / songwriter',
    time: '3:30 - 4:30pm',
    porch: '16 Byng Ave'
  },
  {
    name: 'Craig McNair',
    genre: 'Solo singer / songwriter',
    time: '3:30 - 4:30',
    porch: '2 Lansdowne Rd South'
  },
  {
    name: 'Dan Badger',
    genre: 'Country - 4 piece band',
    time: '4:00 - 5:00pm',
    porch: '29 Brant Road North'
  },
  {
    name: 'Gregory Boivin',
    genre: 'Singer-songwriter, folk, indie folk',
    time: '4:00 - 5:00pm',
    porch: '61 Barrie Street'
  },
  {
    name: 'Charlie Mills',
    genre: 'Singer / songwriter',
    time: '4:00 - 5:00pm',
    porch: '33 Hillcrest Drive'
  },
  {
    name: 'Naked Bear DJ',
    genre: 'Loop Artist / New Disco, Swing House, Electro Funk',
    time: '5:00 - 6:30pm',
    porch: '73 Water Street (Ainslie side)'
  },
  {
    name: 'Acoustic JAK',
    genre: 'Soulful and unique acoustic',
    time: '4:30 - 5:30pm',
    porch: '97 Blair Road'
  },
  {
    name: 'The Harmony Riders',
    genre: 'Soulful and unique acoustic',
    time: '4:30 - 5:30pm',
    porch: '37 Lansdowne Road North'
  },
  {
    name: 'Zenen',
    genre: 'Acoustic, indie, pop',
    time: '5:00 - 6:00pm',
    porch: '68 Hillsboro Driveh'
  },
  {
    name: 'Between Names',
    genre: 'Acoustic classic pop / R&B, originals',
    time: '5:00 - 6:00pm',
    porch: '24 Brant Road North'
  },
  {
    name: 'Cambridge Symphony Orchestra',
    genre: 'Orchestral',
    time: '6:00 - 7:00pm',
    porch: '92 Wentworth Ave'
  }
];
