import styled from '@emotion/styled';

export const PerformersContainer = styled.div``;

export const HrWrapper = styled.div`
    width: 80%;
    margin: 0 auto;
    position; relative;

    hr {
        margin: 15px;
        border: 0;
        height: 1px;
        background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.75),
            rgba(255, 255, 255, 0)
        );
    }
`;

export const Header = styled.h1`
  font-size: 160%;
  text-align: center;
  letter-spacing: 1px;
  font-family: 'Indie Flower', cursive;
  font-weight: 100;
  margin: 0 0 14px;
`;

export const IntroMessage = styled.h3`
  font-weight: 100;
  text-align: center;
`;

export const NamesWrapper = styled.div`
  margin: 0 auto;
  width: 940px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  // ------ Phone
  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const NameCol = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;

  // ------ Phone
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const Name = styled.h3`
  font-weight: 100;
  margin: 0 0 16px;
  color: #5fcbee;
  cursor: pointer;
  position: relative;
  transition: 0.1s ease-in;

  ${(p) =>
    p.isSelected &&
    `
        color: #fff;
        letter-spacing: 0.15px;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0.1em;
            background-color: #ff7a46;
            opacity: 1;
            transform: translate3d(0, 0.2em, 0);
        }
    `};

  ${(p) =>
    !p.isSelected &&
    `
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0.1em;
            background-color: #ff7a46;
            opacity: 0;
            transition: opacity 300ms, transform 300ms;
        }
        
        &:hover::after,
        &:focus::after {
            opacity: 1;
            transform: translate3d(0, 0.2em, 0);
        }
    `}
`;

export const ArtistWrapper = styled.div`
    width: 80%;
    margin: 40px auto 40px;
            
    // ------ Phone
    @media (max-width: 1024px) {
        width: 100%;
        margin: 40px auto 20px;
    };

    h1{
        text-align: center;
        font-weight: 100;
        color: #fff;
        font-family: Beon, serif;
        text-transform: uppercase;
        letter-spacing: 3px;
        margin-bottom: 36px;
        text-shadow: 0 0 13px #fff;
        };
    }
`;
