import styled from '@emotion/styled';

export const Header = styled.h1`
    font-size: 160%;
    text-align: center;
    letter-spacing: 1px;
    font-family: 'Indie Flower', cursive;
    font-weight: 100;
    margin: 0 0 14px
`

export const MessageWrapper = styled.div`
    margin: 40px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ThanksIcon = styled.img`
    width: 100px;
    text-align: center
`

export const Message = styled.h3`
    font-weight: 100;
    text-align: center;
    margin-top: 40px;
`