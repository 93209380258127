export const Faqs = [
  {
    question: 'Porchfest Code of Conduct',
    answer: [
      '* set your speakers to reasonable volume',
      "* be respectful of people's property. Do not litter or trample flower beds",
      "* don't swear or use derogatory language during performances"
    ]
  },
  {
    question: 'Are washrooms available during the event?',
    answer: 'Yes, free public washrooms are available. Please check our map for washroom locations on our Schedule page.'
  },
  {
    question: 'What are the boundaries of Galt Porchfest?',
    answer: 'For 2024 event all performances are across the river from downtown Cambridge, in West Galt. We will definitely expand the area in 2024.'
  },
  {
    question: 'How will I know where the venues are?',
    answer: 'Check the Schedule tab on this site.'
  },
  {
    question: 'I am interested in performing at Porchfest, what do I do?',
    answer: 'Go to our sign-up page. Once you submit your information, you will receive a confirmation email. Signup deadline is August 20th.'
  },
  {
    question: 'I don’t have a porch. Can I still perform?',
    answer: 'Yes! We can match you with a porch. We will contact you a week before the event to let you know your porch address.'
  },
  {
    question: 'What do I need to consider when performing on a porch?',
    answer: ['* music should be appropriate for all ages', '* music should be at unplugged noise levels', '* be prepared for rain or shine']
  },
  {
    question: 'I only have material for less than 30 minutes. Is that okay?',
    answer: 'We can pair you up with another act. Please message hello@galtporchfest.ca'
  },
  {
    question: 'I live in Galt and am interested in hosting an act on my porch.',
    answer: 'Go to our sign-up page. Once you submit your information, you will receive a confirmation email. Sign-up deadline is Friday, August 20th.'
  },
  {
    question: 'I have a porch, but no musicians; can I still host an act?',
    answer: 'Yes! We can match you with an act. We will contact you a week before the event to let you know the musical act who will perform on your porch.'
  },
  {
    question: 'I live in Galt, but I don’t have a porch. Is it possible to host?',
    answer: 'We strive to ensure that all acts have adequate coverage in case of rain, so porches are preferred. Nevertheless, please sign-up and we will be in touch.'
  },
  {
    question: 'What do I need to do as a Porch-host?',
    answer: [
      '* be available to assist the musicians in setting up',
      '* allow the performer access to your porch at least 2 hours before the event',
      '* be prepared for rain or shine',
      '* inform your neighbours of your participation in Porchfest'
    ]
  },
  {
    question: 'I don’t live in the Galt boundary area for the event. Can I still host?',
    answer: 'Unfortunately, no. We are trying to encourage people to walk from venue to venue.'
  }
];
