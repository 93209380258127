import * as s from './Gallery.style';
import { arrayMoveMutable } from 'array-move';
import { motion } from 'framer-motion';
import { useState, useEffect, useContext, useCallback } from 'react';

import { allImages } from 'Images';
import { currentPerformersList, pastPerformersListForGallery } from 'Performers';
import { Select, MenuItem } from '@material-ui/core';

// Context
import AppContext from 'Context';

// Components
import TwoColumnCarousel from './TwoColumnCarousel/TwoColumnCarousel';

const Gallery = () => {
  // Context
  const context = useContext(AppContext);
  const { isMobile } = context;

  // Desktop
  const [allArtistsNames, setAllArtistsNames] = useState([]);
  const [selected, setSelected] = useState(['ALL']);
  const [selectedUrls, setSelectedUrls] = useState([]);

  const filteredUrls = useCallback(
    (namesArr) => {
      const urls = [];

      const filterFn = (arr) => {
        if (arr.length) {
          arr.forEach((name) => {
            const artistImages = allImages.find((artist) => artist.name === name);
            if (artistImages) {
              for (let i = 0; i < artistImages.numberOfImages; i++) {
                urls.push(`img/gallery/thumbnails/${artistImages.name} ${i}.jpg`);
              }
            }
          });
        }
      };

      if (namesArr[0] === 'ALL') filterFn(allArtistsNames);
      else filterFn(namesArr);

      return urls;
    },
    [allArtistsNames]
  );

  // Get all artist names
  useEffect(() => {
    const allNames = ['ALL', 'Miscellaneous'];

    // Past performers that are returning in the current year - excluding new ones
    for (let column in currentPerformersList) {
      currentPerformersList[column].forEach((performer) => !performer.isNew && allNames.push(performer.name));
    }

    // Past performers that are NOT returning in the current year
    pastPerformersListForGallery.forEach((performer) => allNames.push(performer.name));

    // Sort alphabetically
    allNames.sort();

    // Place "ALL" to be on top and "Miscellaneous" to be second
    const indexOfALL = allNames.findIndex((item) => item === 'ALL');
    const indexOfMisc = allNames.findIndex((item) => item === 'Miscellaneous');
    arrayMoveMutable(allNames, indexOfALL, 0);
    arrayMoveMutable(allNames, indexOfMisc, 1);

    setAllArtistsNames(allNames);
  }, []);

  // Initial load of all photos
  useEffect(() => {
    const allUrls = filteredUrls(allArtistsNames);
    shuffleArray(allUrls);
    setSelectedUrls(allUrls);
  }, [allArtistsNames, filteredUrls]);

  // Update selected urls array
  useEffect(() => {
    const newSelectedList = filteredUrls(selected);
    shuffleArray(newSelectedList);
    setSelectedUrls(newSelectedList);
  }, [selected, filteredUrls]);

  // Desktop name filter
  const handleNameClick = (name) => {
    let newSelectedArray = JSON.parse(JSON.stringify(selected));
    const existingIndex = selected.findIndex((n) => n === name);

    // Clicked on 'ALL'
    if (name === 'ALL') {
      // 'ALL' already selected / don't do anything
      if (existingIndex > -1) return;
      // Select 'ALL' / clear everything else
      setSelected(['ALL']);
    }

    // Clicked on a name
    else {
      // Already exists / remove it
      if (existingIndex > -1) {
        newSelectedArray.splice(existingIndex, 1);
        newSelectedArray.length ? setSelected(newSelectedArray) : setSelected(['ALL']);
      }
      // Does not exist / add it
      else {
        const indexOfAll = selected.findIndex((n) => n === 'ALL');
        indexOfAll > -1 && newSelectedArray.splice(indexOfAll, 1);
        newSelectedArray.push(name);
        setSelected(newSelectedArray);
      }
    }
  };

  // Shuffle images
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  return (
    <motion.nav initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.2 }}>
      <s.Header>
        <span style={{ color: '#ff7a46' }}>
          2021 <span style={{ color: '#fff' }}>/</span> 2022 <span style={{ color: '#fff' }}>/</span> 2023
        </span>{' '}
        <span style={{ color: '#ff7a46' }}>G</span>allery{' '}
      </s.Header>
      {isMobile ? (
        // Mobile view ---------------------------------
        <s.GalleryContainerMobile>
          <s.FilterWrapperMobile>
            <s.FilterMobileLabel>Filter by Name</s.FilterMobileLabel>
            <s.ArtistSelectDropdown>
              <Select
                variant={'outlined'}
                name={'artist-name'}
                value={selected[0]}
                onChange={(e) => setSelected([e.target.value])}
                style={{
                  height: '39px',
                  width: '100%',
                  marginTop: '6px',
                  fontFamily: 'Indie Flower',
                  borderRadius: '6px',
                  color: '#ff7a46',
                  backgroundColor: '#2b3240',
                  boxShadow: '0 0 10px #ff7a46',
                  textTransform: 'uppercase',
                  letterSpacing: '2px'
                }}
              >
                {allArtistsNames.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </s.ArtistSelectDropdown>
          </s.FilterWrapperMobile>
          <hr />

          <s.ImagesWrapperMobile>
            {selectedUrls.map((url) => (
              <motion.nav initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.2 }} key={url}>
                <s.ImageMobile
                  src={url}
                  onClick={() => {
                    const fullSizeUrl = url.replace('thumbnails', 'full-size');
                    const newWindowImageUrl = `${window.location.origin}/${fullSizeUrl}`;

                    return window.open(newWindowImageUrl, '_blank');
                  }}
                />
                <hr />
              </motion.nav>
            ))}
          </s.ImagesWrapperMobile>
        </s.GalleryContainerMobile>
      ) : (
        // Desktop view --------------------------------
        <s.GalleryContainerDesktop>
          <s.FilterWrapper>
            {allArtistsNames.map((name, index) => {
              const isSelected = selected.some((n) => n === name);
              return (
                <>
                  <s.FilterName key={name} onClick={() => handleNameClick(name)} isSelected={isSelected}>
                    {name}
                  </s.FilterName>
                  {index === 1 && (
                    <s.HrWrapper>
                      <hr />
                    </s.HrWrapper>
                  )}
                </>
              );
            })}
          </s.FilterWrapper>
          <s.PhotosWrapper>
            <TwoColumnCarousel selectedUrls={selectedUrls} allArtistsNames={allArtistsNames} shuffleArray={shuffleArray} />
          </s.PhotosWrapper>
        </s.GalleryContainerDesktop>
      )}
    </motion.nav>
  );
};

export default Gallery;
